import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-raspodela',
        category: 'Ova aplikacija se koristi za vršenje ravnomerne raspodele komunalnih, budžetskih i sudskih predmeta svim javnim izvršiteljima na području Republike Srbije.',
        title: 'Raspodela',
        link: 'raspodela',
        id: 'raspodela'

    },
    {
        image: 'image-eraspodela',
        category: 'Aplikacija koja služi za ravnomernu raspodelu predloga za izvršenje u kojima se kao dužnici javljaju direktni i indirektni korisnici budžetskih sredstava.',
        title: 'e-Raspodela',
        link: 'eraspodela',
        id: 'eraspodela'

    },
    {
        image: 'image-enadzor',
        category: 'Ova aplikacija napravljena za unos, praćenje i pretragu podnetih pritužbi i disciplinskih prijava na rad javnih izvršitelja, kao i preuzimanje odgovarajućih izveštaja.',
        title: 'e-Nadzor',
        link:'enadzor',
        id:'enadzor'
    },
    
    
    {
        image: 'image-eobjedini',
        category: 'Web aplikacija napravljena za pravilno objedinjavanje velikog broja različitih izveštaja. Služi za uklanjanje potrebe za ručnim računanjem velikog broja podataka.',
        title: 'e-Objedini',
        link: 'eobjedini',
        id: 'eobjedini'

    }
]

class PortfolioListKJI extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <a  href={`${value.link}`}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner" id={value.id}>
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4><a href={`${value.link}`}>{value.title}</a></h4>
                                    <p>{value.category}</p>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`${value.link}`}>Detalji</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioListKJI;