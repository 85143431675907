import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn } from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy'
import { IconContext } from "react-icons/lib";
import {Helmet} from "react-helmet";
import {FiCheck} from "react-icons/fi";
import ReactModal from "react-modal";
import {Button} from "react-bootstrap";
const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]
const costumStyleModal = {
    content : {
        width: '40%',
        height:'40%',
        right:'30%',
        left:'30%'
      
    },
    overlay: {zIndex: 1000}
  };
class HeaderThree extends Component{
    constructor(props) {
        super(props);
        this.state={
        isOpen:false
        }
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })

    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    

    stickyHeader () {}
     toggleModal = (currMode=-1) => {
        this.setState({
          isOpen: !this.state.isOpen,
          
        });}

    render(){
       
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky');
               // document.querySelector('logoimg').setAttribute('src','/assets/images/logo/stankoviclogopink.png');
               document.getElementById('logoimg').setAttribute('src','/assets/images/logo/logo.png');
                

            }else{
                document.querySelector('.header--fixed').classList.remove('sticky');
                document.getElementById('logoimg').setAttribute('src','/assets/images/logo/logo_light.png');
                
            }
            
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        
        
        
            
            

        return(
            <div>
            <Helmet> 
            <script src="assets/js/main.js" type="text/javascript"></script>
            </Helmet>
            <div className="cookies" >
                <span className="message">Ovaj sajt koristi kolačiće za poboljšanje korisničkog iskustva. Korišćenjem ovog sajta se slažete sa <a href="#"  onClick={this.toggleModal}>uslovima</a></span>
                <span className="mobile">Ovaj sajt koristi kolačiće, <a   href="#">pročitaj više</a></span>
                <label htmlFor="checkbox-cb" className="close-cb"><FiCheck/></label>
            </div>
            
            
            
            <header className={`header-area header-style-two header--fixed ${color}`}>
               

                <div className="header-wrapper">
                
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                        <a href="/"><img src="/assets/images/logo/logo_light.png" id="logoimg" style={{ width:"18em", height:'auto', marginBottom: "10px" }} alt="logo" /></a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" items={['home','about','service','portfolio','contact']} currentClassName="is-current" offset={-200}>
                            
                                <li><a href="/">Početna</a></li>
                                <li><a href="/about">O nama</a></li>
                                <li><a href="/snaga">Naša snaga</a></li>
                                <li ><a href="/portfolio">Portfolio</a>
                                   
                                </li>
                                <li><a href="/contact">Kontakt</a></li>
                            </Scrollspy>
                        </nav>
                    </div>
                    
                    <div className="header-right">
                    <a href="/standardi"><img src="/assets/images/iso_9001_27001.png" id="certimg" style={{ width:"5em", height:'auto'}} alt="sertifikat" />
                    </a>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20" style={{color:"green"}}>
                            <span onClick={this.menuTrigger} className="menutrigger text-white" ><IconContext.Provider value={{color:'green'}} ><FiMenu /></IconContext.Provider></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>

            <ReactModal isOpen={this.state.isOpen} onRequestClose={this.toggleModal} style={costumStyleModal}>
                   <div style={{top:'0',float:'right',position:'sticky', zIndex:'999'}}> 
               
                </div>
                <br/><br/><br/>
                    <div class="text-center">
                        <p>Obavezni kolačići čine stranicu upotrebljivom omogućavajući osnovne funkcije kao što je navigacija stranicom. Stanković Soft koristi kolačiće koji su nužni za ispravno funkcioniranje naše web stranice kako bismo omogućili pojedine tehničke funkcije i tako Vam osigurali pozitivno korisničko iskustvo. Prihvatanjem dozvoljavate korišćenje ovih kolačića.</p>
                    </div>
                    <br/>
                    <br/>
                     <div class="text-center">
                    <Button className="btn-danger"onClick={this.toggleModal} >Zatvori</Button>
                    </div>
                </ReactModal>
            </div>
        )
    }
}
export default HeaderThree;
