import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import Breadcrumb from "../common/Breadcrumb";
import Gallery from "../../blocks/GalleryCronusGo";
import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { FaLinkedinIn } from "react-icons/fa";





const images = [
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-login.png',
        original: '/assets/images/cronusGo/cronusgo-login.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-todo.png',
        original: '/assets/images/cronusGo/cronusgo-todo.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-map.png',
        original: '/assets/images/cronusGo/cronusgo-map.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-raport.png',
        original: '/assets/images/cronusGo/cronusgo-raport.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-raport2.png',
        original: '/assets/images/cronusGo/cronusgo-raport2.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-map-done.png',
        original: '/assets/images/cronusGo/cronusgo-map-done.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-done.png',
        original: '/assets/images/cronusGo/cronusgo-done.png'
    }

]

const selectFakulteti = [
    { value: 'masfak2', label: 'Mašinski fakultet, Univerzitet u Nišu' },
    { value: 'pmf', label: 'Prirodno-matematički fakultet, Univerzitet u Nišu' },
    { value: 'vps', label: 'Akademija strukovnih studija "Južna Srbija"' },
   
];
class FakultisKomunikator extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            width: window.innerWidth,
            height: window.innerHeight,
            selectedFakultet: { value: 'pmf', label: 'Prirodno-matematički fakultet, Univerzitet u Nišu' }
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleChange = (selectedFakultet) => {
        this.setState({ selectedFakultet }
            //  ,() =>console.log(`Option selected:`, this.state.selectedFakultet)
        );
    };
    render() {
        var Galerija = <Gallery />;
        var link = <Link to={"/downloads/komunikator/" + this.state.selectedFakultet.value + ".zip"} target="_blank" download><a class="rn-button-style--2  btn-solid">Preuzmi</a></Link>
        if (this.state.width <= 1000) {
            Galerija = <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} />;
        }
        return (
            <React.Fragment>
                <PageHelmet pageTitle=""
                    pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže."
                    metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>CronusGo"
                    keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
                />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <Breadcrumb title="Fakultis Komunikator" />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="section-title">
                                            <h3 className="title">Fakultis Komunikator</h3>
                                        </div>
                                        <p className="subtitle"></p>

                                        <p>Aplikacija napravljena za ostvarivanje komunikacije između Fakultisa i servisa JISP-a ličnim korisničkim sertifikatom, ali se može lako proširiti i na druge eksterne aplikacije koje zahtevaju prijavu ličnim sertifikatom. </p>
                                        <p>Pravljena je u .NET Core radnom okviru za Windows operativne sisteme ali je kompatibilna i sa Linux operativnim sistemima. </p>
                                        <p>Fakultis Komunikator radi sa bazom podataka <a href="/fakultis">Fakultis</a>-a i nakon uspostavljanja komunikacije sa JISP-ovim servisima proverava da li je osoba sa priloženim sertifikatom registrovana i na fakultis radi dodatne bezbednosti.
                                            Ako je korisnik prethodno registrovao sertifikat u Fakultis biće ulogovan sa mogućnostima da proveri podatke o svojoj ustanovi koji se nalaze u JISP-u, kao i da pošalje u JISP one podatke koji nedostaju.</p>
                                        <div className="section-title" style={{ marginTop: 30 }}>
                                            <h4 className="title">Uputstvo</h4>
                                        </div>


                                        <p>Svaki fakultet koji želi ovu mogućnost ima posebnu verziju aplikacije koja radi sa bazom njihovog fakultisa. Ukoliko Vaš fakultet ima svoju verziju ove aplikacije potrebno je izabrati sa kog ste fakulteta i preuzeti instalaciju Fakultis Komunikatora.</p>
                                        <p>Nakon toga pokrenuti instalaciju i instalirati program prateći instrukcije installera. Instalacija će napraviti prečicu za program na desktopu, odakle može da se pokrene. Pre pokušaja prijavljivanja potrebno je ispravno ubaciti ličnu kartu u čitač.</p>
                                        <p>Kada kliknete na dugme za prijavu, zatražiće pin koji ste dobili od sertifikacionog tela prilikom dobijanja sertifikata. Posle unosa pina aplikacija će Vas obavestiti ako se nešto nepredviđeno dogodilo ili će Vas ulogovati u fakultis sa pravima slanja podataka u JISP.</p>
                                        

                                        <div className="section-title" style={{ marginTop: 30 }}>
                                            <h4 className="title">Pre koriščenja Komunikatora potrebno je da obezbedite:</h4>
                                        </div>
                                        <ul style={{ fontSize: 18 }}>
                                            <li>Sertifikat izdat od MUP-a ili nekog drugog sertifikacionog tela</li>
                                            <li>Fakultis aplikaciju za fakultet na kojem radite, kao i nalog sa dovoljnim nivoom pristupa i registrovan pomenuti sertifikat</li>
                                            <li>Računar na kojem su instalirani svi neophodni programi i drajveri za korišćenje sertifikata, kao i uređaj za čitanje sertifikata ukoliko je potreban (čitač lične karte, itd)</li>
                                            <li>Konekcija sa internetom</li>
                                            <li>Bilo koji savremeni web pretraživač</li>
                                            
                                        </ul>
                                        <Select options={selectFakulteti} onChange={this.handleChange} value={this.state.selectedFakultet}/>
                                        <br/>
                                        
                                       {link}
                                        <div className="ostalo" style={{ textAlign: 'center', marginTop: 50 }}>
                                            <div className="slide-btn"><a className="rn-button-style--2  btn-solid" href="../portfolio#fakultis" >Nazad na aplikacije</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default FakultisKomunikator;
