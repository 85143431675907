import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import Breadcrumb from "../common/Breadcrumb";
import Gallery from "../../blocks/GalleryUpitnik";
import ImageGallery from "react-image-gallery";


const images = [
	{
        thumbnail: '/assets/images/upitnik/upitnik-login.png',
        original: '/assets/images/upitnik/upitnik-login.png'
    },
	{
        thumbnail: '/assets/images/upitnik/upitnik-pocetna.png',
        original: '/assets/images/upitnik/upitnik-pocetna.png'
    },
	{
        thumbnail: '/assets/images/upitnik/upitnik-ankete.png',
        original: '/assets/images/upitnik/upitnik-ankete.png'
    },
	{
        thumbnail: '/assets/images/upitnik/upitnik-upitnici.png',
        original: '/assets/images/upitnik/upitnik-upitnici.png'
    },
	{
        thumbnail: '/assets/images/upitnik/upitnik-izbor-predmeta.png',
        original: '/assets/images/upitnik/upitnik-izbor-predmeta.png'
    },
    {
        thumbnail: '/assets/images/upitnik/upitnik-izvestaj1.png',
        original: '/assets/images/upitnik/upitnik-izvestaj1.png'
    },
	{
        thumbnail: '/assets/images/upitnik/upitnik-izvestaj2.png',
        original: '/assets/images/upitnik/upitnik-izvestaj2.png'
    },
    {
        thumbnail: '/assets/images/upitnik/upitnik-unos-vremena.png',
        original: '/assets/images/upitnik/upitnik-unos-vremena.png'
    }
	
]

class Upitnik extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          width:window.innerWidth,
          height:window.innerHeight
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    render(){
        var Galerija=<Gallery/>;
        if (this.state.width<=1000){
            Galerija=<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />;
        }
        return(
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>Upitnik"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
                />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                {/* Start Breadcrump Area */}
               <Breadcrumb title="Upitnik"/>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
										<div className="section-title">
											<h3 className="title">O Upitniku</h3>
										</div>
                                        <p>Upitnik je<strong> dodatna aplikacija uz Fakultis</strong> koja koristi podatke iz Fakultisa, ali funkcioniše samostalno i nezavisno.</p>
                                        <p>Ideja Upitnika je da se kreira po jedna anketa za svaku ciljnu grupu ispitanika koji popunjavaju različite upitnike. Na primer posebne ankete i upitnike za studente završnih godina studija, za studente doktorskih studija, za nastavnike, za ostale studente itd.</p>
                                        <div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Administratorski deo Upitnika</h4>
										</div>
                                        <p>Logovanjem na administratorski deo aplikacije, korisnik dobija pre svega mogućnost kreiranja novih upitnika kroz dodavanje novih grupa pitanja i samih pitanja, kao i mogućih odgovora za svako pojedinačno pitanje. Mogućnosti za odgovore su svedene na to da se bira jedan od ponuđenih odgovora.</p>
                                        <p>Administrator može kreirati i postavljati na neaktivne ili aktivne više anketa. Ovde se podrazumeva da je Anketa skup više upitnika.  Administrator, takođe definiše kad je anketa aktivna i kada studenti mogu da je popunjavaju. Isto tako, u mogućnosti je da generiše naloge za studente i nastavnike koji mogu pristupiti anketiranju.</p>
                                        <div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Korisnički deo Upitnika</h4>
										</div>
                                        <p>Logovanjem na korisnički deo portala „Upitnik“, korisnik je u mogućnosti da popuni upitnike koji su predviđeni za popunjavanje. Sam portal se brine o tome da studentu ponudi po jedan upitnik za svaki predmet koji je student slušao u semestru i školskoj godini za koju popunjava upitnik. </p>
                                        <div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Izveštaji</h4>
										</div>
                                        <p>U administratorskom delu postoji mogućnost da dobijete sve izveštaje o već urađenim anketama i to u formi i na način propisan i predložen od strane Univerziteta u Nišu. Ovde je moguće odraditi doradu izveštaja prema posebnim potrebama Vašeg fakulteta.</p>
                                        <p>U aplikaciju su ugrađeni svi upitnici koji su definisani od strane Rektorata Univerziteta u Nišu i koji su usvojeni kao standardni upitnici za anketiranje na svim fakultetima Univerziteta u Nišu.</p>
                                            <h4 className="title"> Povezivanje</h4>
                                        <p>Postoji mogućnost da se ova aplikacija poveže sa studentskim portalom i da se studentima ponudi popunjavanje upitnika direktno sa studentskog portala.</p>
                                        <p>Alikacija se može povezati i sa Nastavničkim portalom gde svako od nastavnika može videti rezultate anketa za ispte koje drži.</p>
                                       
                                        <div className="gallery">
											{Galerija}
										</div>
                                        <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                 <div className="slide-btn"><a className="rn-button-style--2  btn-solid" href="../portfolio#upitnik" >Nazad na aplikacije</a></div>
                                </div>
                                       
                                       
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Upitnik;
