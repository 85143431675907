import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor,FiPhoneCall } from "react-icons/fi";
import {FaDev,FaPhone} from "react-icons/fa";
import {MdUpdate} from "react-icons/md"
import {AiOutlineCloudServer,AiOutlineExperiment,AiOutlineFileExclamation,AiOutlineTool,AiOutlineThunderbolt} from "react-icons/ai"
//import {Link as ScrollLink, animateScroll as scroll} from 'react-scroll';

const ServiceList = [
    {
        icon: <FaDev />,
        title: 'Razvoj Web aplikacija',
        description: 'Razvijamo najkvalitetnije ideje da bi kreirali programe koji zadovoljavaju potrebe korisnika i koji su konkurentni na tržištu.',
        divid:'razvoj'
    },
    {
        icon: <MdUpdate />,
        title: 'Održavanje i ažuriranje',
        description: 'Svesni smo jakog tempa kojim se WEB tehnologije razvijaju i spremni smo da obezbedimo isti tempo napredovanja kod aplikacija koje razvijamo.',
        divid:'odrzavanje'
    },
   
    {
        icon: <AiOutlineExperiment />,
        title: 'Test verzije programa',
        description: 'Ukoliko korisnik želi da se upozna sa našom aplikacijom, imamo posebne test verzije, sa odvojenim bazama podataka.',
        divid:'test'
    },
    { 
        icon: <AiOutlineTool />,
        title: 'Brzo otklanjanje grešaka',
        description: 'Ukoliko dođe do neke greške ili neočekivanog ponašanja aplikacije, naši programeri će u najkraćem roku otkriti razlog i ukloniti bilo kakvu grešku.',
        divid:'fix'
    },
    { 
        icon: <FiPhoneCall />,
        title: 'Korisnička podrška',
        description: 'Dostupni smo našim korisnicima putem telefona ili e-maila i spremni smo da uvek čujemo novu ideju ili predlog za dalji razvoj naših aplikacija.',
        divid:'support'
    },
    { 
        icon: <AiOutlineThunderbolt />,
        title: 'Automatizacija',
        description: 'Stanković Soft je sa razlogom određen kao ključni partner mnogim institucijama i klijentima jer nastoji da obezbedi bolje proizvode kroz automatizaciju celokupnog poslovanja.',
        divid:'digital'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={`/snaga#${val.divid}`}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
