import React, { Component } from "react";

const PortfolioListContent = [

    {
        image: 'image-feba',
        category: 'Aplikacija za vođenje predmeta po ugovoru o kupovini potraživanja',
        title: 'Feba',
        link: 'feba',
        id: 'feba'

    },
    {
        image: 'image-cronus-bank',
        category: 'Aplikacija koja služi bankama/poslodavcima za lakše vođenje i praćenje naplate na osnovu dobijenog naloga za prinudnu naplatu od strane javnih izvršitelja ili suda.',
        title: 'Cronus Bank',
        link: 'cronusbank',
        id: 'cronusbank'

    },
]

class PortfolioListBliskeUstanove extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <a  href={`${value.link}`}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner" id={value.id}>
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4><a href={`${value.link}`}>{value.title}</a></h4>
                                    <p>{value.category}</p>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`${value.link}`}>Detalji</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioListBliskeUstanove;