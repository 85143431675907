
import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";

import Breadcrumb from "../common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import Gallery from "../../blocks/GalleryRaspodela";
import ImageGallery from "react-image-gallery";

const images = [
	{
        
        original: '/assets/images/raspodela/raspodela-login.png',
        thumbnail: '/assets/images/raspodela/raspodela-login.png'

    },
	{
        
        original: '/assets/images/raspodela/raspodela-pocetna.png',
        thumbnail: '/assets/images/raspodela/raspodela-pocetna.png'

    },
	{
        
        original: '/assets/images/raspodela/raspodela-izvestaji.png',
        thumbnail: '/assets/images/raspodela/raspodela-izvestaji.png',
    },
    {
        
        original: '/assets/images/raspodela/raspodela-ucitavanje.png',
        thumbnail: '/assets/images/raspodela/raspodela-ucitavanje.png'
    }
	
	
]

class Raspodela extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    render(){
        var Galerija=<Gallery/>;
        if (this.state.width<=1000){
            Galerija=<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />;
        }
        return(
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>Raspodela"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
               />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                {/* Start Breadcrump Area */}
                <Breadcrumb title="Raspodela"/>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
										<div className="section-title">
											<h3 className="title">O Raspodeli</h3>
										</div>
                                        <p className="subtitle">Raspodela je aplikacija implementirana za potrebe Komore javnih izvršitelja.</p>
                                        <p>Raspodela prikazuje evidenciju obavljenih raspodela, podeljenih predmeta,kao i učitanih izveštaja izvršitelja.
                                            Iz aplikacije je moguće štampati razne vrste izveštaja. Moguće je preuzeti izveštaje o raspoređenim komunalnim ili sudskim predmetima u višim ili privrednim sudovima.
                                        </p>

                                        <p>Izveštaj o raspodeljenim predmetima dobijaju izvršitelj i poverilac, s tim što izvršitelj dobija izveštaj o predmetima koji su njemu raspodeljeni, a poverilac dobija izveštaj o raspodeljenim predmetima za svakog izvršitelja.</p>

                                        <div className="gallery">
											{Galerija}
										</div>
                                        <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                 <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href="../portfolio#raspodela" >Nazad na aplikacije</a></div>
                                </div>

                                       
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Raspodela;
