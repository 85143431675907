import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";

class ListFeba extends Component{
    render(){
        var lista = [
            {
                content:'Unos predmeta po potpisanom i zavedenom ugovoru sa cedentom je moguć Masovnim automatskim unosom (učitavanjem datoteke) ili Manuelnim unosom'
            },
            {
                content:'Ravnomerna podela predmeta među pravnicima'
            },
            {
                content:'Poseban sistem alarmiranja koji je prilagodljiv potrebama agencije, pruža da FEBA sama obaveštava ulogovanog korisnika o bitnim rokovima i dnevnim zadacima'
            },
            {
                content:'Lako definisanje sporazuma na osnovu unete visine rate ili broja rata'
            },
            {
                content:'Implementirana advokatska tarifa i obračun duga i kamate u drugim valutama'
            },
            {
                content:'Tok predmeta u kome su podaci podeljeni u logičke celine'
            },
            {
                content:'Prilagođena štampa izlaznih dokumenata i koverata uz štampu barkoda za lakše evidentiranje povratnica/dostavnica'
            },
            {
                content:'Direktna komunikacija sa komorom JI radi dodeljivanja nadležnog JI i komunikacija sa Cronus aplikacijom za lako praćenje toka sprovođenja izvršenja od strane JI'
            },
            {
                content:'Učitavanje izvoda tekućeg računa i automatsko knjiženje uplata po pozivu na broj'
            },
            {
                content:'Automatizovano generisanje naloga za prenos i izvoz paketa naloga za prenos'
            },
            {
                content:'Statistički pregled i razni izveštaji i pretrage daju mogućnost uvida u uspešnost poslovanja agencije i praćenje rada po predmetima '
            },
            {
                content:'Poseduje i različite nivoe pristupa putem aktivnog korisničkog naloga'
            },
            {
                content:'Web aplikacija kojoj se pomoću interneta pristupa sa različitih uređaja'
            },
        ];
        return(
            <ul className="list-style--1">
                {lista.map((elem, index) => {
                    return <li key={ index }><FiCheck /> <strong>{elem.strong}</strong> {elem.content}</li>;
                })}
            </ul>
        )
    }
}
export default ListFeba;