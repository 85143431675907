import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";

class ListCloud extends Component{
    render(){
        var names = [
           ' Nema potrebe za ikakvom instalacijom, korišćenje aplikacije uz pomoć naloga i internet pretraživača',
            'Smanjeno vreme ažuriranja softvera',
           ' Infrastruktura računara korisnika postaje jeftinija',
           ' Pristup softveru iz različitih kancelarija',
           ' Fleksibilno održavanje softvera i primenjivanje update-a',
           ' Sve podatke koje unesete u program čuvaće se na serveru na kome se nekoliko puta dnevno radi backup, tako da mogućnost gubitka podataka ne postoji.',
          '  Čak iako neke podatke greškom unesete, izmenite, ili obrišete, moguće je vratiti iz nekog od brojnih backup-ova u stanje pre greške, kako bi nesmetano nastavili sa radom.'
        ];
        return(
            <ul className="list-style--1">
                {names.map((name, index) => {
                    return <li key={ index }><FiCheck /> {name}</li>;
                })}
            </ul>
        )
    }
}

export default ListCloud;