import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const TabOne = [
	{
        image: 'fakultis-login',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-login.png'
    },
	{
        image: 'fakultis-dash',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-dash.png'
    },
	{
        image: 'fakultis-pretraga',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-pretraga.png'
    },
    {
        image: 'fakultis-raspored',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-raspored.png'
    },
	{
        image: 'fakultis-student',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-student.png'
    },
	{
        image: 'fakultis-student-unos',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-student-unos.png'
    },
	{
        image: 'fakultis-student-ispit',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-student-ispit.png'
    },
	{
        image: 'fakultis-student-skolarina',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-student-skolarina.png'
    },
	{
        image: 'fakultis-zapisnik',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-zapisnik.png'
    },
	{
        image: 'fakultis-zapisnik2',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-zapisnik2.png'
    },
	{
        image: 'fakultis-statistika',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-statistika.png'
    },
	{
        image: 'fakultis-predmet',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-predmet.png'
    },
	{
        image: 'fakultis-rang',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-rang.png'
    },
	{
        image: 'fakultis-nastavnik',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-nastavnik.png'
    },
	{
        image: 'fakultis-nagrade',
        bigImage: '/assets/images/fakulteti/fakultis/fakultis-nagrade.png'
    },
	
]

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;
        return (
            <div>
                <main className="page-wrapper">
                    <div className="rn-portfolio-area bg_color--1 line-separator">
                        <div className="container">
                            <div className="row">
                                {TabOne.map((value , index) => (
                                    <div className="col-lg-3" key={index}>
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={TabOne[tab1].bigImage}
                                                nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                                prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                                onMovePrevRequest={() =>
                                                this.setState({
                                                    tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                                })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        tab1: (tab1 + 1) % TabOne.length,
                                                    })
                                                }
                                            />
                                        )}
                                        <div className="item-portfolio-static">
                                            <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                                <div className="portfolio-static">
                                                    <div className="thumbnail-inner">
                                                        <div className="thumbnail">
                                                            <a href="#portfolio-details">
                                                                <img src={`/assets/images/fakulteti/fakultis/${value.image}.png`} width='200' height='100' alt="Portfolio Images"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}


export default Gallery;