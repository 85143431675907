import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";

class ListCronusBank extends Component{
    render(){
        var names = [
            
    'Jednostavan i brz unos podataka sadržanih u primljenom zaključku',
    'Jedinstveni delovodni/interni broj i broj i datum primljenog zaključka',
    'Unos podataka olakšan korišćenjem veb servisa Narodne banke Srbije',
   ' Dopremanje podataka iz internog informacionog sistema banke putem veb servisa ili direktnim upisom potrebnih podataka u bazu',
    'Integracija sa aplikacijom Cronus koju koristi više od 180 javnih izvršitelja',
    'Automatski obračun različitih tipova kamate',
    'Precizan, jasan i jednostavan pregled svih podataka vezanih za primljeni zaključak',
   ' Pregled primljenih zaključaka po klijentu/dužniku',
   ' Hronološki tok primljenih i donetih akata',
   ' Generisanje akata banke u word formatu prema unapred definisanom sadržinom sa podacima iz programa',
   ' Evidencija podataka o poslatim pošiljkama putem pošte',
   ' Slanje elektronske pošte preko programa',
    'Skeniranje, otpremanje i čuvanje dokumenata vezanih za zaključak',
    'Pregled stanja duga sa obračunatom kamatom u svakom trenutku',
    'Pregled realizovanih naloga za prenos – po paketu ili zaključku',
    'Excel izveštaj o toku sprovođenja',
    'Zbirni izveštaj svih klijenata/dužnika sa promenama na tekućim računima',
    'Kalendar oročenja',
    'Izvoz naloga za prenos u formatu pogodnim za uvoz u interni informacioni sistem banke',
   ' Automatsko namirenje više zaključaka koji se odnose na istog klijenta/dužnika',
   ' Pretraga zaključaka i klijenata i generisanje izveštaja na osnovu dobijenog rezultata',
   ' Pregled statističkih podataka',
   ' Prilagođavanje sadržine akata i elektronskih poruka prema zahtevima banke'

            ];
        return(
            <ul className="list-style--1">
                {names.map((name, index) => {
                    return <li key={ index }><FiCheck /> {name}</li>;
                })}
            </ul>
        )
    }
}

export default ListCronusBank;