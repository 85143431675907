import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function AllPages(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { pdf } = props;
  const scale = .33;
  return (
      
    <Document
      file={pdf}
      renderMode="canvas"
      loading={<div>Pdf datoteka se učitava...</div>}
      //options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}

    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page width={window.screen.width} scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
}