import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';





const TabOne = [
	{
        image: 'eindeks-login',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-login.png'
    },
	{
        image: 'eindeks-pocetna',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-pocetna.png'
    },
	{
        image: 'eindeks-prijava-1',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-prijava-1.png'
    },
	{
        image: 'eindeks-prijava-2',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-prijava-2.png'
    },
	{
        image: 'eindeks-3',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-3.png'
    },
    {
        image: 'eindeks-polozeni-ispiti',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-polozeni-ispiti.png'
    },
	{
        image: 'eindeks-upisani-semestri',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-upisani-semestri.png'
    },
	{
        image: 'eindeks-upisani-semestri',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-upisani-semestri.png'
    },
	{
        image: 'eIndeks-1',
        bigImage: '/assets/images/fakulteti/eindeks/eIndeks-1.png'
    },
	{
        image: 'eindeks-raspored-ispita',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-raspored-ispita.png'
    },
	{
        image: 'eindeks-prijave-ispita-admin',
        bigImage: '/assets/images/fakulteti/eindeks/eindeks-prijave-ispita-admin.png'
    },
	
]

class GalleryIndeks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;
        return (
            <div>

            

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 line-separator">
                    <div className="container">
                        <div className="row">
                            {TabOne.map((value , index) => (
                                <div className="col-lg-3" key={index}>
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={TabOne[tab1].bigImage}
                                            nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                            prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    tab1: (tab1 + 1) % TabOne.length,
                                                })
                                            }
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a href="#portfolio-details">
                                                            <img src={`/assets/images/fakulteti/eindeks/${value.image}.png`} width='200' height='100' alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}

            </main>
            {/* End Page Wrapper  */}

            
                       
            </div>
        )
    }
}


export default GalleryIndeks;