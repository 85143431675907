import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";

class ListEindeks extends Component{
    render(){
        var names = [
            'Elektronska prijava ispita',
            'Raspored časova predmata koje student sluša',
            'Raspored preostalih ispita predmeta koje student sluša',
            'Uvid u sve prošle prijave ispita',
            'Uvid u finansijsko stanje na računu (da li i koliko student ima uplaćen novac na račun fakulteta)',
            'Istoriju uplata',
            'Podaci za uplatu',
            'Podaci o kolokvijumima i terminima za konsultacije',
            'Vesti o fakultetu i ispitnim rokovima',
        ];
        return(
            <ul className="list-style--1">
                {names.map((name, index) => {
                    return <li key={ index }><FiCheck /> {name}</li>;
                })}
            </ul>
        )
    }
}
export default ListEindeks;