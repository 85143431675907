import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";

class ListCronus extends Component{
    render(){
        var lista = [
            {
                strong:'Jednostavan unos, pregled i izmena podataka. ',
                content:'Program je potpuno prilagođen da prihvata unos sudskih (I.I, I.Iv, O.Prm, O.Pm), kao i komunalnih predmeta (I.Ivk), predmeta izvršenja na osnovu rešenja za naplatu neplaćene sudske takse (I.nt), nasleđenih predmeta drugog izvšitelja, tzv. POM predmete. Takođe, u spisak upisnika su uvršteni upisnici II-ZAM, IIV-ZAM, IIVK-ZAM, INT-ZAM, OPM-ZAM i OPRM-ZAM, kao i upisnici za dobrovoljno namirenje novčanog potraživanja II-DOB, IIV-DOB i IIVK-DOB koji su definisani novim Pravilnikom.'
            },
            {
                strong:'Karakteristični predmeti. ',
                content:'Postoji mogućnost označavanja da li je u pitanju predmet po čl. 547. ZIO, po članu 166 ZIO, čl. 121. Zakona o radu, alimentacija/renta, sudski penali, protivizvršenje, preuzet predmet, nenovčanog ili neodređenog potraživanja, i vođenje predmeta u skladu sa odabranim tipom.'
            },
            {
                strong:'Vođenje PIP predmeta. ',
                content:'Združivanje sopstvenih ili predmeta drugih izvršitelja radi namirenja zajedničkih potraživanja prodajom nepokretnosti ili pokretnih stvari (čl. 157. i čl. 235. ZIO 2015) '
            },
            {
                strong:'Potraživanje u stranim valutama.',
                content:'Program podržava zavođenje potraživanja u domaćoj i stranoj valuti i to po više valuta u istom predmetu, kao i kompletan obračun kamate (zakonske zatezne, na javne prihode, ugovorene, po ECB stopi). Mogućnost dobijanja detaljnog izveštaja o načinu obračuna kamate (kamatni list). '
            },
            {
                strong:'Potraživanje po poveriocima.',
                content:'Ukoliko u predmetu postoji veći broj poverilaca moguće je označiti potraživanje i troškove za svakog posebno i posebno razduživati. '
            },
            {
                strong:'Prilagođen ZIO i tarifama. ',
                content:'U programu je moguće voditi predmete po ZIO 2011, ZIO 2015 i ZIO 2019. Prilagođen je vođenju troškova po tarifi 50/12, izmeni tarife 4/16, javnoizvršiteljskoj tarifi 59/16, kao i po tarifi 93/2019. '
            },
            {
                strong:'Kalkulator kamate i troškova bez zavođenja predmeta. ',
                content:''
            },
            {
                strong:'Prilagođen pravilniku o načinu vođenja evidencije o postupcima izvršenja i obezbeđenja. ',
                content:'Obuhvata sve upisnike i izveštaje opisane u pravilnicima. Prijemna, otpremna i knjiga arhive, vođenje jemstva, zamolnica, pravnih lekova, zahteva za otklanjanje nepravilnosti, kompletan unos podataka o finansijskom poslovanju. '
            },
            {
                strong:'Godišnji izveštaji. ',
                content:'Program sadrži sve izveštaje po zahtevu Komore Javnih izvršitelja i Ministarstva. Izveštaji se mogu generisati za prethodne godine, ali i za tekuću godinu i na taj način je moguće praćenje uspešnosti rada izvršitelja. Dobijanje svih izveštaja za Ministarstvo tačno i pravovremeno.'
            },
            {
                strong:'Brzo generisanje tehničkog obrasca za otpremanje u eZIO aplikaciju  ',
                content:'– Program poseduje mogućnost generisanja Obrasca za zavođenje podataka u elektronsku evidenciju javnih izvršitelja - tehničkog obrasca koji obuhvata sve podatke unete u program na dnevnom nivou.'
            },
            {
                strong:'Izveštaji prilagođeni specifičnim potrebama izvršitelja i organizacija podataka prema potrebama izvršitelja. ',
                content:''
            },
            {
                strong:'Masovni unos svih akata i radnji u program',
                content:'(bira se dokument/radnja koji se unosi za više predmeta odjednom) '
            },
            {
                strong:'Masovno brisanje greškom unetih akata, troškova, ekspedicije. ',
                content:''
            },
            {
                strong:'Napredna pretraga predmeta',
                content:''
            },
            {
                strong:'Lako praćenje predmeta u toku sprovođenja ',
                content:'– pregled predmeta koji su u toku sprovođenja ili u toku sporazuma, a uplate kasne'
            },
            {
                strong:'Praćenje logovanja korisnika',
                content:', kao i ograničavanje njihovih ovlašćenja za rad u programu uz mogućnost definisanja perioda pristupa programu za svakog korisnika pojedinačno'
            },
            {
                strong:'WEB platforma programa',
                content:'i prilagođenost pristupa sa mobilnih i tablet uređaja '
            },
            {
                strong:'Redovni backup ',
                content:'na zakupljenom domenu servera. '
            },
            {
                strong:'Custom template-ing sistem za svakog izvršitelja ',
                content:'(učitavanje template-a se vrši u najkraćem mogućem roku, u zavisnosti od komplikovanosti podataka, ali najviše u roku od 5 radnih dana od dana podnošenja potpunog zahteva). '
            },
            {
                strong:'Mogućnost štampe omota spisa i arhivskog omota.',
                content:''
            },
            {
                strong:'Kalendar ',
                content:'koji prati događaje, kao što su zakonski rokovi, zakazani popisi, prodaje, i sl., i na iste upozoravaju korisnike softvera a prema željenoj dinamici (omogućen poseban interval obaveštavanja za svaki događaj), i dr. '
            },
            {
                strong:'Nepokretnost ',
                content:'- unos, izmena, pretraga, zakazivanje razgledanja, prve prodaje. Štampa pratećih zaključaka i zapisnika.'
            },
            {
                strong:'Kretanje predmeta.',
                content:'Evidencija o kretanju predmeta ili grupe predmeta (kod izvršitelja, u sudu, u evidenciji, arhiva). Automatsko generisanje alarma za podsećanje o isteku roka evidencije.'
            },
            {
                strong:'Vođenje arhiviranih predmeta. ',
                content:'Prilikom arhiviranja predmeta, program nudi prvi slobodan broj arhive u tekućoj godini. Mogućnost štampe arhivskog omota i knjige arhive.'
            },
            {
                strong:'Alarm',
                content:'koji će vas upozoravati na rokove (rok za dostavu pismena, izjavljivanje prigovora, dobrovoljno plaćanje, pravnosnažnost rešenja i dr.), evidenciju predmeta, kao i na samostalno definisane događaje. Usaglašavanje vremena pojavljivanja alarma sa potrebama kancelarije. '
            },
            {
                strong:'Automatizacija.',
                content:'Omogućuje olakšan rad u programu. Prilikom donošenja akata moguće je automatski dodati izvršnu radnju i/ili troškove, zadužiti dužnika sa 50% nagrade/naknade nakon unosa informacije o prijemu zaključka/rešenja o izvršenju, automatsko uvećanje nagrade u zavisnosti od unete/preduzete radnje. Automatizacije su prilagodljive potrebana kancelarije i nisu obavezne.'
            },
            {
                strong:'Sporazum. ',
                content:'Mogućnost vođenja sklopljenih sporazuma sa dužnikom uz praćenje dospeća svake rate. '
            },
            {
                strong:'Grupe predmeta.',
                content:' Vođenje predmeta po grupama i lak rad sa celom grupom predmeta. Grupe se generišu po potrebi kancelarije na primer grupa predmeta za slanje zahteva MUP-u, PIO fondu, grupa predmeta spremna za arhiviranje, izlazak na teren radi popisa i dr.'
            },
            {
                strong:'Podela predmeta po pomoćnicima. ',
                content:''
            },
            {
                strong:'Pisarnica ',
                content:'koja vam omogućuje da u svakom trenutku znate gde se nalazi vaš predmet i za koju je radnju neko od saradnika zadužen.'
            },
            {
                strong:'Elektornsko slanje zahteva PIO fondu. ',
                content:'Ovde postoji mogućnost da sačuvate fajl sa imenima i matičnim brojevima dužnika koji ćete kasnije učitati direktno u aplikaciju CROSO. '
            },
            {
                strong:'Kreiranje zahteva za NBS i učitavanje odgovora na zahtev. ',
                content:'Na lak i jednostavan način odabirom predmeta možete generisati fajl koji je potrebno učitati u web aplikaciju NBS-a. Isto tako, postoji mogućnost učitavanja odgovora i prepis tekućih računa u bazu podataka što ubrzava postupak sprovođenja. '
            },
            {
                strong:'Komunikacija sa NBS-om. ',
                content:'Postoji mogućnost da se za uneto pravno lice (stranku u postupku ili poslodavca) na jedan klik dobiju inforamacije o svim otvorenim tekućim računima i lak prepis istih u bazu podataka. '
            },
            {
                strong:'Unos izvoda sa namenskog računa izvršitelja, kao i raspodela iznosa od uplate za svaki predmet. ',
                content:'Program u ovom delu ima mogućnost da učita izvod namenskog računa, prepozna uplate po pozivu na broj, raspodelu uplaćenih iznosa, knjiženje uplate dužnika u program, kao i mogućnost izvoza datoteke za elektronsko knjiženje kod banke (paket naloga za prenos). Time se u bazi podataka čuvaju sve potrebne informacije o uplati i uplatiocu i prenosu i primaocu potrebne za izradu izveštaja propisanih pravilnikom i olakšava rad jer se izostavlja dupli unos podatka (podaci se unose u aplikaciju Cronus na osnovu kojih se dobija paket naloga za prenos koji se učitaju u e-banking aplikaciju radi relizacije istih).'
            },
            {
                strong:'Učitavanje PIO specifikacije uplata. ',
                content:'– Omogućeno jednostavno učitavanje XMLa o prenosima iz PIO fonda, i raspodele uplata na predmete u Cronusu.'
            },
            {
                strong:'Jemstvo ',
                content:'– vođenje svih podataka o jemstvu propisanih Pravilinikom o načinu vođenja evidencije'
            },
            {
                strong:'Obaveštavanje. ',
                content:'Mogućnost direktnog obaveštavanja poverioca ili zastupnika o uplatama dužnika i stanju duga po svakom predmetu ili slanje toka predmeta putem elektronske pošte ili SMS poruka. '
            },
            {
                strong:'Reoniranje ',
                content:'– mogućnost određivanja pripadnosti bilo koje adrese reonu grada, radi jednostavnijeg i bržeg uručivanja pošte. '
            },
            {
                strong:'Brzi elektronski uvoz predmeta ',
                content:'po dostavljenim podacima poverioca u elektronskom obliku u odgovarajućoj elektronskoj formi. '
            },
            {
                strong:'Ekspedicija. ',
                content:'Unos svih ekspedicija (za grupu predmeta i pojedinačno), unos povratnica, vraćenih koverti, izgubljene pošte. Automatsko pravljenje knjige pošte na dnevnom nivou. '
            },
            {
                strong:'Automatska štampa koverata prema kovertama koje već koristi kancelarija ili standardizovanih koverata definisanih ugovorom sklopljenim između JP Pošta Srbije i Komore javnih izrvršitelja.',
                content:''
            },
            {
                strong:'Štampa bar-koda i R/RS broja na kovertama direktno iz Cronus programa. ',
                content:'– Veliko ubrzanje rada pomoću bar kodova prilikom evidenicje primljenih ili vraćenih koverata. Mogućnost slanja više dokumenta u jednu kovertu.'
            },
            {
                strong:'Izvoz za EPK.',
                content:'Izvoz specifikacije podobne za učitavanje u poštinu aplikaciju za dodelu RS brojeva.'
            },
            {
                strong:'Knjiga internih dokumenata ',
                content:'– evidencija odlaznih i dolaznih pismena kancelarije javnog izvršitelja (dokumenti koji se ne odnose na predmete)'
            },
            {
                strong:'Baza institucija. ',
                content:'Program poseduje bazu sudova, mup-ova, pio filijala, matičnih službi, banaka i dr. čiji se podaci mogu koristiti prilikom ekspedicije, štampe obrazaca/zahteva i dr. Korisnik ima mogućnost da sam dodaje i menja postojeći spisak institucija. '
            },
            {
                strong:'Precizno praćenje razduženja dužnika za svaki predmet. ',
                content:'Mogućnost samostalnog određivanja prioriteta razduženja (troškovi, kamata, glavni dug, nagrada). Presek stanja duga na željeni datum. Mogućnost obračuna stanja duga na datum u bliskoj budućnosti.'
            },
            {
                strong:'Praćenje ravnomerne raspodele, kao i direktno slanje izveštaja o primljenim predmetima Komori javnih izvršitelja kroz program. ',
                content:''
            },
            {
                strong:'Program je potpuno usklađen sa najnovijim zahtevima poverioca o naplati ili ne naplati PDV-a na troškove poverioca – predujam. Unos podataka da li je poverilac oslobođen plaćanja predujma, kao i mogućnost unosa predujma bez zaduživanja dužnika. ',
                content:''
            },
            {
                strong:'Predujmljivanje obraćanja državnom organu, kao i RAT bez obračuna PDV-a. ',
                content:''
            },
            {
                strong:'Evidencija iskorišćenog dela predujma ',
                content:''
            },
            {
                strong:'Fakturisanje.',
                content:'Sistem fakturisanja i vođenja evidencije o izdatim i plaćenim fakturama, kao i mogućnost izvoza faktura za potrebe uvoza u odvojeni knjigovodstveni program. Sistem podržava izdavanje predračuna, avansnih, periodičnih i konačnih računa.'
            },
            {
                strong:'Definisanje stanja predmeta i automatska promena stanja predmeta na osnovu odrađenih akcija. ',
                content:''
            },
            {
                strong:'Otpremanje datoteke. ',
                content:'Sačuvajte dokumente vezane za predmet u elektronskoj verziji skeniranjem svih primnjenih pismena i otpremanjem na server uz vezivanje sa aktima na toku predmeta. Lako koršćenje i pregled otrpemljenih datoteka. Ova opcija je moguća samo uz iznajmljivanje jačeg servera. '
            },
            {
                strong:'Mogućnost pregleda unetih podataka po nalogu korisnika – izveštaj o sprovedenom poslu ',
                content:''
            },
            {
                strong:'Zaključavanje arhiviranog predmeta ',
                content:''
            }
            
            
        ];
        return(
            <ul className="list-style--1">
                {lista.map((elem, index) => {
                    return <li key={ index }><FiCheck /> <strong>{elem.strong}</strong> {elem.content}</li>;
                })}
            </ul>
        )
    }
}
export default ListCronus;