import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                <title>{this.props.pageTitle} Stanković Soft</title>
                    <meta name="description" content={`${this.props.pageDescription}`} />
                    <meta name="title" content={`${this.props.metaTitle}`}/>
                    <meta name="keywords" content={`${this.props.keywords}`}/>
                    <meta name="author" content="Ivan Stankovic"/>
                    <meta name="contact" content="office@stankovicsoft.com"/>
                    <meta name="robots" content="index, follow"/>
                    <meta name="copyright" content="StankovicSoft"/>
                    <meta name="rating" content="safe for kids"/>
                    <meta name="abstract" content="Stankovic soft"/>
                     </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
