import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import PortfolioListIzvrsitelji from "../elements/portfolio/PortfolioListIzvrsitelji";
import PortfolioListBliskeUstanove from "../elements/portfolio/PortfolioListBliskeUstanove";
import PortfolioListFakulteti from "../elements/portfolio/PortfolioListFakulteti";
import PortfolioListMobile from "../elements/portfolio/PortfolioListMobile";
import PotrfolioListKJI from "../elements/portfolio/PortfolioListKJI"
import PortfolioVaga from '../elements/portfolio/PortfolioVaga';

const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>Portfolio"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
                />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            
            <Breadcrumb title={'Portfolio'}   />

            <main className="page-wrapper">

                <div className="portfolio-area pb--40 bg_color--1" id="vaga">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <PortfolioVaga styevariation="text-center mt--40" column="offset-md-2 col-md-8 col-sm-12 col-12" />
                            </div>
                        </div>
                    </div>
                </div>
			
                <div className="portfolio-area pb--80 bg_color--1" id="izvrsitelji">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <PortfolioListIzvrsitelji styevariation="text-center" column="offset-md-2 col-md-8 col-sm-12 col-12" />
                            </div>
                            
                        </div>
                    </div>
                </div>

               <div className="portfolio-area pb--80 bg_color--1" id="fakulteti">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--20">
                                    <h3 className="title">Za fakultete i visokoškolske ustanove</h3>
                                    <p>Ceo sistem naših aplikacija za fakultete je osmišljen da kroz ove aplikacije svi oni koji čine fakultet imaju značajno olakšan posao. To podrazumeva studentsku službu, pravnu službu, finansijsku službu, nastavnike i saradnike, a posebno studente.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <PortfolioListFakulteti stylevariation="text-center mt--40" />
                            </div>
                            
                        </div>  
                    </div>
                </div>
                 <div className="portfolio-area pb--80 bg_color--1" id="izvrsitelji">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">Mobilne aplikacije</h3>
                                    <p>Znajući da ćemo značajno poboljšati iskustvo korisnika razvili smo i sledeće mobilne aplikacije:</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <PortfolioListMobile styevariation="text-center mt--40" column="col-lg-6 col-md-6 col-sm-12 col-12" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="portfolio-area pb--80 bg_color--1" id="izvrsitelji">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h3 className="title">Aplikacije za potrebe Komore javnih izvršitelja</h3>
                                    <p>U saradnji sa Komorom javnih izvršitelja napravili smo aplikacije za olakšavanje njihovog rada.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <PotrfolioListKJI styevariation="text-center mb--30" column="col-lg-6 col-md-6 col-sm-12 col-12" />
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="portfolio-area pb--80 bg_color--1" id="ostale">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">Ostale aplikacije</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <PortfolioListBliskeUstanove styevariation="text-center mt--40" column="col-md-6 col-sm-12 col-12" />
                            </div>
                        </div>
                    </div> 
                </div>
            </main>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            <Footer />

        </>
        
    )
}

export default Portfolio;