import React, { Component } from "react";
import Particles from "react-particles-js";

class Breadcrumb extends Component{
    render(){
        const { title , parent} = this.props;
        return(
            <React.Fragment>
                <div className="slider-activation slider-creative-agency with-particles">
                <div className="frame-layout__particles">
                        <Particles
                            params={{
                                "particles": {
                                    "number": {
                                        "value": 70
                                    },
                                    "size": {
                                        "value": 3
                                    },
                                    "line_linked":{
                                        "width":2
                                    },
                                },
                                "interactivity": {
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "repulse"
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                <div className=" rn-bg-color pt--20 pb--50 breadcrumb" data-black-overlay="5">
                
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">{title}</h2>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Breadcrumb;

