import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Gallery from "../../blocks/Gallery";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import ImageGallery from "react-image-gallery";
import Breadcrumb from "../common/Breadcrumb";

const images = [
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-login.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-login.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-dash.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-dash.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-pretraga.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-pretraga.png'
    },
    {
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-raspored.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-raspored.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-student.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-student.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-student-unos.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-student-unos.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-student-ispit.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-student-ispit.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-student-skolarina.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-student-skolarina.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-zapisnik.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-zapisnik.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-zapisnik2.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-zapisnik2.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-statistika.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-statistika.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-predmet.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-predmet.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-rang.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-rang.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-nastavnik.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-nastavnik.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/fakultis/fakultis-nagrade.png',
        original: '/assets/images/fakulteti/fakultis/fakultis-nagrade.png'
    },

	
];
const listaKorisnika=[
    {
        univerzitet:", Univerzitet u Nišu",
        fakulteti:['Ekonomski fakultet','Elektronski fakultet',
        'Fakultet sporta i fizičkog vaspitanja','Fakultet umetnosti','Filozofski fakultet','Građevinsko-arhitektonski fakultet','Mašinski fakultet','Medicinski fakultet','Pedagoški fakultet u Vranju','Poljoprivredni fakultet u Kruševcu','Prirodno-matematički fakultet','Tehnološki fakultet u Leskovcu']

    },
    {
        univerzitet:", Univerzitet u Kragujevcu",
        fakulteti:['Agronomski fakultet u Čačku','Ekonomski fakultet','Fakultet inženjerskih nauka','Fakultet medicinskih nauka','Fakultet tehničkih nauka u Čačku','Fakultet za hotelijerstvo i turizam iz Vrnjačke Banje','Filološko-umetnički fakultet','Pedagoški fakultet u Užicu','Pravni fakultet','Prirodno-matematički fakultet']


    },
    {
        univerzitet:"",
        fakulteti:['Medicinski fakultet Univerziteta u Prštini, sa privremenim sedištem u Kosovskoj Mitrovici','Fakultet za sport i fizičko vaspitanje Univerziteta u Prštini, sa privremenim sedištem u Leposaviću','Akademija strukovnih studija "Južna Srbija", Leskovac']

    },
    
];

class Fakultis extends Component{
    
    constructor () {
        super()
        this.state = {
          isOpen: false,
          mode: -1,
          width:window.innerWidth,
          height:window.innerHeight
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        
    }

    componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    openModal () {
        this.setState({isOpen: true})
    }
    toggleModal = (currMode=-1) => {
        this.setState({
          isOpen: !this.state.isOpen,
          mode:currMode
        });
        
    }

     
    
    render(){

             var FakultisModal;
            
             var Galerija=<Gallery/>;
             if (this.state.width<=1000){
                 Galerija=<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />;
             }


            return( 
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>Fakultis"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
               />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                
                <Breadcrumb title="Fakultis"></Breadcrumb>

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <p className="subtitle"></p>
                                        
                                        <p>
											<strong>Integrisani informacioni sistem fakulteta</strong> predstavlja kompletno rešenje za vođenje studentske evidencije, vođenje osnovne kadrovske evidencije, izradu rasporeda časova, evidenciju nastavnih planova, evidentiranje školarina i drugih uplata studenata, dobijanje različitih statističkih izveštaja, štampu uverenja i diploma i organizaciju prijemnog ispita
										</p>
										
										<p>
											Softver prati studenta od trenutka njegovog prijavljivanja za polaganje prijemnog ispita, samog polaganja prijemnog ispita, upisa na studije, ostvarene rezultate tokom studija, izdavanja raznih potvrda i uverenja, pa sve do konacnog izdavanja diplome.
										</p>
										<p>
											Podeljen je u nekoliko modula koji funkcionišu kao zajednička celina (upis na studije, evidencija studenata, izveštaji, predmeti, nastavnici, finansije, modul za slanje SMS poruka, administracija celokupnog sistema, praćenje specijalizacija za medicinske fakultete) koji čine softver jednostavnim za korišćenje. 
										</p>
                                        
										<p>Implementiran je na osnovu iskustva u radu službi fakulteta, kao i na osnovu zakonskih odredbi Zakona o visokom obrazovanju i osnovnih principa Bolonjske deklaracije.</p>
										
										<p>
											Prilagodjen je za sve nivoe studija (osnovne, master, doktorske, integrisane, specijalističke, strukovne).  
										</p>
                                        <p>Kompletnu administraciju sistema moguće je raditi iz samog softvera, sa administratorskim pravima. Postoji poseban deo aplikacije koji je dostupan samo administratoru sistema na fakultetu, čime je omogućeno upravljanje raznim opcijama direktno iz programa,koji daje korisniku sa administratorskim pravima mogućnost da konfiguriše aplikaciju, svodeći potrebu za intervencijama programera na minimum.</p>
                                        
										<div style={{ backgroundColor:'#ffecb3', padding:20, borderRadius:40 }}>
										<br/>
										<div className="section-title">
											<h4 className="title">Naši zadovoljni klijenti:</h4>
										</div>
                                        {
                                            <ol style={{fontSize:18, letterSpacing:1}}>
                                            {listaKorisnika.map((name,index)=>(
                                                name.fakulteti.map((fakultet,index2)=>(
                                                    <li >{fakultet}{name.univerzitet}</li>
                                                ))
                                                
                                            ))}
                                            </ol>
                                        }
										</div>
										<br/><br/><br/> 

                                        <div className="portfolio-details">
											<div className="section-title">
												<div className="section-title">
													<h4 className="title">Izgled i funkcionalnosti</h4>
												</div>
											</div>
											<p className="subtitle"></p>
                                        </div>
										<p> 
										
											<li><strong>Upis na studije</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Obrazac za upis kandidata na studije</li>
												<li style={{listStyleType:'circle'}}>Pregled prijavljenih kandidata</li>
												<li style={{listStyleType:'circle'}}>Štampanje spiskova prijavljenih kandidata</li>
												<li style={{listStyleType:'circle'}}>Raspoređivanje kandidata po prostorijama za polaganje prijemnog ispita</li>
												<li style={{listStyleType:'circle'}}>Kompletan izvoz svih podataka o kandidatima u Excel i Word</li>
												<li style={{listStyleType:'circle'}}>Unos rezultata prijemnog ispita sa posebnim pravima pristupa</li>
												<li style={{listStyleType:'circle'}}>Rangiranje i pregled kandidata</li>
												<li style={{listStyleType:'circle'}}>Štampanje jedinstvene i konačne rang liste kandidata</li>
												<li style={{listStyleType:'circle'}}>Izvoz jedinstvene i konačne rang liste, zajedno sa svim ostalim podacima o kandidatima u Excel i Word</li>
												<li style={{listStyleType:'circle'}}>Automatski upis u prvu godnu osnovnih akademskih studija, uz definisanje školarine za datog studenta, kao i predmeta koje će slušati na prvoj godini studija</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Evidencija studenata</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Pretraga studenata po velikom broju kriterijuma, izvoz urađene pretrage u MS Excel</li>
												<li style={{listStyleType:'circle'}}>Detaljni dosije studenta/matična knjiga, osnovni podaci, osvojeni espb bodovi, položeni i prijavljeni ispiti, uplate i isplate studenta, kazne studenta, detalji o prijemnom ispitu, kao i diplomskom</li>
												<li style={{listStyleType:'circle'}}>Upis/overa semestra, unos izabranih predmeta i unos školarine </li>
												<li style={{listStyleType:'circle'}}>Prijava predmeta koji se slušaju tokom školske godine</li>
												<li style={{listStyleType:'circle'}}>Unos svih detalja o završnom ispitu</li>
												<li style={{listStyleType:'circle'}}>Ispis sa fakulteta</li>
												<li style={{listStyleType:'circle'}}>Prelaz sa druge visokoškolske ustanove</li>
												<li style={{listStyleType:'circle'}}>Priznavanje ispita, fakultativni ispiti</li>
												<li style={{listStyleType:'circle'}}>Vođenje evidencije o predispitnim obavezama</li>
												<li style={{listStyleType:'circle'}}>Štampa raznih spiskova studenata</li>
												<li style={{listStyleType:'circle'}}>Analitički izveštaj o dugovanjima studenta u toku školske godine</li>
												<li style={{listStyleType:'circle'}}>Podrška različitim modulima u  okviru istog studijskog programa</li>
												<li style={{listStyleType:'circle'}}>Mogućnost vođenja evidencije studentima koji studiraju u različitim centrima, tj. gradovima u kojima fakultet ima svoje ispostave</li>
												<li style={{listStyleType:'circle'}}>Štampa nacrta i dodatka diplome na srpskom i engleskom jeziku</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Izveštaji</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Različite vrste potvrda koje se odnose na status studenta na studijama</li>
												<li style={{listStyleType:'circle'}}>Uverenje o položenim ispitima</li>
												<li style={{listStyleType:'circle'}}>Uverenje o diplomiranju</li>
												<li style={{listStyleType:'circle'}}>Pregled studenata po različitim kriterijumima</li>
												<li style={{listStyleType:'circle'}}>Pregled broja upisanih studenata</li>
												<li style={{listStyleType:'circle'}}>Izveštaj o odbranjenim završnim radovima</li>
												<li style={{listStyleType:'circle'}}>Izveštaj o ispisanim studentima</li>
												<li style={{listStyleType:'circle'}}>Izveštaj o izmirenim finansijskim obavezama</li>
												<li style={{listStyleType:'circle'}}>Pregled upisanih i diplomiranih studenata</li>
                                                <li style={{listStyleType:'circle'}}>Spiskovi studenata po predmetima</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Statističko praćenje uspeha studenata</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Analiza prolaznosti po predmetima, studijskim programima, ispitnim rokovima, školskim godinama</li>
												<li style={{listStyleType:'circle'}}>Analiza prohodnosti u narednu godinu studija</li>
												<li style={{listStyleType:'circle'}}>Izveštaj o nepoloženim ispitima</li>
												<li style={{listStyleType:'circle'}}>Izveštaj o uspehu (osvojeni ESPB bodovi i prosečna ocena) po godinama studija, mogućnost pretrage po tim parametrima</li>
												<li style={{listStyleType:'circle'}}>Rangiranje studenata koji konkurišu za upis u narednu godinu studija</li>
												<li style={{listStyleType:'circle'}}>Razni izveštaji za potrebe akreditacije</li>
											</ul>
										
											<li style={{marginTop:30}}><strong>Prijavljivanje ispita</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Evidencija ostvarenih bodova na predispitnim obavezama studenata</li>
												<li style={{listStyleType:'circle'}}>Prijava ispita (po studentu, predmetu i spisku slušalaca)</li>
												<li style={{listStyleType:'circle'}}>Prijava fakultativnih ispita</li>
												<li style={{listStyleType:'circle'}}>Izrada rasporeda ispita</li>
												<li style={{listStyleType:'circle'}}>Izrada rasporeda dežurstava</li>
												<li style={{listStyleType:'circle'}}>Štampa zapisnika za polaganje, kao i popunjenog zapisnika sa statističkim podacima</li>
												<li style={{listStyleType:'circle'}}>Podela zapisnika po danima i ispitivačima. Mogućnost definisanja posebnih kriterijuma za raspoređivanje studenata po zapisnicima</li>
												<li style={{listStyleType:'circle'}}>Unos ocena</li>
												<li style={{listStyleType:'circle'}}>Evidencija nerazvedenih zapisnika</li>
												<li style={{listStyleType:'circle'}}>Praćenje izmirenja finansijskih obaveza prilikom prijave ispita</li>
												<li style={{listStyleType:'circle'}}>Prijava ispita i štampanje zapisnika po centrima i zgradama</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Finansije</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Uvoz uplata studenata iz dnevnog izveštaja iz Trezora</li>
												<li style={{listStyleType:'circle'}}>Pojedinačno evidentiranje uplata studenata za školarinu, ispite, izdavanje potvrda itd</li>
												<li style={{listStyleType:'circle'}}>Izveštaji o uplatama i dugovanjima studenata</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Slanje SMS poruka</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Definisanje grupa za slanje SMS poruka direktno sa pretrage studenata i zaposlenih</li>
												<li style={{listStyleType:'circle'}}>Slanje poruka pojedinim studentima i zaposlenima</li>
												<li style={{listStyleType:'circle'}}>Izveštaji o poslatim SMS porukama</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Predmeti</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Kompletna evidencija predmeta po studijskim programima i nastavnim planovima</li>
												<li style={{listStyleType:'circle'}}>Angažovanja nastavnika po  predmetima</li>
												<li style={{listStyleType:'circle'}}>Izveštaj o opterećenosti nastavnika</li>
                                                <li style={{listStyleType:'circle'}}>Izveštaj o zauzetosti prostorija i studenata</li>
												<li style={{listStyleType:'circle'}}>Pretraga predmeta po različitim kriterijumima</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Raspored časova</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Izrada rasporeda časova</li>
												<li style={{listStyleType:'circle'}}>Praćenje zauzatosti nastavnika i učionica</li>
												<li style={{listStyleType:'circle'}}>Štampa rasporeda</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Kadrovska evidencija</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Evidencija o zaposlenima na fakultetu</li>
												<li style={{listStyleType:'circle'}}>Pretraga zaposlenih po različitim kriterijumima</li>
												<li style={{listStyleType:'circle'}}>Pregled isteka izbora u zvanje</li>
												<li style={{listStyleType:'circle'}}>Štampa raznih potvrda i uverenja</li>
												<li style={{listStyleType:'circle'}}>Spisak po radnom stažu</li>
												<li style={{listStyleType:'circle'}}>Jubilarne nagrade</li>
												<li style={{listStyleType:'circle'}}>Prestanak radnog odnosa</li>
												<li style={{listStyleType:'circle'}}>Starosna penzija</li>
											</ul>
											
											<li style={{marginTop:30}}><strong>Administracija sistema</strong></li>
											<ul style={{fontSize:14}}>
												<li style={{listStyleType:'circle'}}>Kompletnu administraciju sistema je moguće raditi iz samog sistema. Napravljen je poseban deo aplikacije koji je dostupan samo administratoru sistema na fakultetu i autorima softvera, čime je omogućeno upravljanje raznim opcijama sistema direktno iz programa</li>
											</ul>

                                             <li style={{ marginTop: 30 }}><strong>Integracija sa JISP informacionim sistemom</strong></li>
                                             <ul style={{ fontSize: 14 }}>
                                                    <li style={{ listStyleType: 'circle' }}>Omogućeno slanje podataka iz Fakultisa u JISP</li>
                                                    <li style={{ listStyleType: 'circle' }}>Omogućen pregled podataka iz JISP-a u Fakultis-u</li>
                                                    <li style={{ listStyleType: 'circle' }}>Podaci se mogu slati i masovno i pojedninačno</li>
                                                    {/* <li style={{ listStyleType: 'circle' }}>Povezivanje sa JISP-om smo omogućili i serverskim i ličnim sertifikatom (za povezivanje ličnim sertifikatom potrebno je preuzeti naš <a href="/komunikator">Komunikator</a>)</li> */}
                                             </ul>
										</p>
										
										<div className="gallery">
											{Galerija}
										</div>
                                        <div className="col-lg-12">
                                    <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                        <div className="slide-btn"><a className="rn-button-style--2  btn-solid" href="../portfolio#fakultis" >Nazad na aplikacije</a></div>
                                    </div>
                                </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                    {/* Start Single Portfolio  *
                    <div className="portfolio-area ptb--120" >
                        <div className="container">
                            <div classname="row">
                                <div className="portfolio-details">
                                    <div className="inner">
										<div className="section-title">
											<div className="section-title">
												<h3 className="title">Detaljnije opcije</h3>
											</div>
										</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                
                                 {listFakultis.map((value , index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--40" key={value.id}>
                                        <a  onClick={()=>this.toggleModal(value.id) } style={{cursor:'pointer'}}>
                                        <div className="portfolio">
                                            <div className="thumbnail-inner">
                                                <div className={`thumbnail ${value.image}`}></div>
                                                <div className={`bg-blr-image ${value.image}`}></div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                   <h4> <a>{value.option}</a></h4>
                                                    <div className="portfolio-button">
                                                        <a className="rn-btn" onClick={()=>this.toggleModal(value.id)}>Detalji</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                ))}
                                </div>
                              </div> 
                             </div>  
                                {/* End Single Portfolio  */}
               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Fakultis;
