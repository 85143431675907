import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-3',
        category: 'Aplikacija za javne izvršitelje koja je do detalja usmerena pre svega ka potpunoj automatizaciji vođenja predmeta. Prateći sve važeće zakone i propise omogućavaju jednostavano, brzo i pouzdano vođenje evidencije o radu javnoizvršiteljskih kancelarija.',
        title: 'Cronus',
        link: 'cronus',
        id: 'cronus'
    }
]

class PortfolioListIzvrsitelji extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <a  href={`${value.link}`}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner" id={value.id}>
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4><a href={`${value.link}`}>{value.title}</a></h4>
                                    <p>{value.category}</p>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`${value.link}`}>Detalji</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </a>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioListIzvrsitelji;