import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import AllPagePdfViewer from "../component/pdf/all-pages";
import Pdf9001Srb from "../../public/assets/docs/pdf/iso9001_rs.pdf";
import Pdf9001Eng from "../../public/assets/docs/pdf/iso9001_en.pdf";
import Pdf27001Srb from "../../public/assets/docs/pdf/iso27001_rs.pdf";
import Pdf27001Eng from "../../public/assets/docs/pdf/iso27001_en.pdf";
import SectionTitle from "../elements/common/SectionTitle"
class StandardiPdf extends Component {
    lang='rs';
    
    constructor(props){
        super(props);
        this.lang=props['lang'];
    }
    
    render(){
        return(
        <>
        <PageHelmet pageTitle="Standardi"/>
            <Header/>
            
            <Breadcrumb title="Standardi"/>
            {/*Start politika kvaliteta i bezbednosti */}
            <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/politika_kvaliteta_bezbednosti.png" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                       
                                        <div className="row mt--30">
                                            
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h2 className="title">Politika kvaliteta i bezbednosti informacija </h2>
                                                    <p>Stanković Soft je posvećen sprovođenju i poboljšavanju sistema menadžmenta kvalitetom i sistema
                                                        menadžmenta bezbednosti informacija u cilju zaštite inofrmacija i imovine Stanković Soft-a, prema
                                                        principima i zahtevima standarda ISO 9001:2015 i ISO/IEC 27001:2013 što obuhvata:
                                                    </p>
                                                <ul>
                                                    <li>Posvećenost ispunjavanju primenljivih zahteva koji su u vezi sa kvalitetom i
                                                        bezbednosti informacija;
                                                    </li>
                                                    <li>Neprestano unapređenje kvaliteta svojih usluga kako bi smo obezbedili ispunjavanje
                                                        zahteva i očekivanja naših korisnika;
                                                    </li>
                                                    <li>Stalna modernizacija opreme, usavršavanje i obrazovanje svih zaposlenih radi
                                                        unapređenja kvaliteta znanja, sposobnosti, kompetencija i veština, kao i savetovanje naših klijenata;
                                                    </li>
                                                    <li>Obuka za bezbednost informacija kojom su obuhvaćeni svi zaposleni i zaineteresovane
                                                        strane u cilju bezbednosti informacija u organizaciji i kod klijenata;
                                                    </li> 
                                                    <li>Sticanje poverenja i ugleda kod klijenata i svih zainteresovanih strana;</li>
                                                    <li>Utvrđivanje i upravljanje procesima koji doprinose zadovoljstvu klijenata;
                                                    </li> 
                                                    <li>Odlučivanje na osnovu prikupljenih i analiziranih podataka koji se odnose na naše usluge;
                                                    </li> 
                                                    <li>Razvijanje partnerskih odnosa sa korisnicima, isporučiocima i zainteresovanim stranama;
                                                    </li> 
                                                    <li>Zaštita informacija od neovlašćenog pristupa uz dostupnost informacija korisnicima gde imaju prava pristupa; </li> 
                                                    <li>Zaštita integriteta informacija od neovlašćene izmene;</li> 
                                                    <li>Osiguranje poverljivosti informacija i da informacije neće biti otkrivene neovlašćenim osobama;</li> 
                                                    <li>Procena i upravljanje rizicima po bezbednost informacija i informacionih resursa i u
                                                        cilju smanjenja uticaja pretnji po bezbednost informacija;
                                                    </li> 
                                                    <li>Ispunjavanje zakona i propisa primenljivih na poslovanje Stanković Soft-a;
                                                    </li> 
                                                    <li>Ostvarivanje definisanih ciljeva kvaliteta i bezbednosti informacija;
                                                    </li> 
                                                </ul>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Start politika kvaliteta i bezbednosti/}


           {/*Start 9001 */}
            <div className="section-title text-center service-style--3 mb--30 ptb--30">
                <h2 className="title">ISO 9001</h2>
                <p>ISO 9001 je međunarodni standard koji sadrži zahteve za sistem upravljanja kvalitetom u poslovnoj organizaciji koje organizacija mora ispuniti da bi uskladila svoje poslovanje sa međunarodno priznatim normama.</p>
                <p>U cilju nastavka obezbeđenja kvaliteta naših usluga, standardizacije poslovanja i zadovoljstva naših korisnika, agencija Stanković soft je implementirala standard ISO 9001 koji se odnosi na sistem menadžmenta kvalitetom. Implementacijom ovog standarda potvrđujemo kvalitet pruženih usluga sa namerom da se isti kontinuirano nastavi na najvišem nivou, uz poštovanje svih procedura poslovanja kao i do sada, a sve u cilju učvršćivanja poslovnih veza sa našim korisnicima, kako postojećim, tako i budućim.</p>
            </div>
            <div className="row ptb--30">
                <div className="col-lg-1"></div>
                <div className="col-lg-5 col-md-12">
                    <AllPagePdfViewer pdf={Pdf9001Srb} />
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 col-md-12">
                    <AllPagePdfViewer pdf={Pdf9001Eng} />
                </div>
            </div>
            {/*End 9001*/}

            {/*Start 27001 */}
            <div className="section-title text-center service-style--3 mb--30 ptb--30">
                <h2 className="title">ISO 27001</h2>
                <p>Međunarodni standard ISO 27001 je standard koji podrazumeva uspostavljanje, primenu, održavanje i stalno poboljšavanje sistema menadžmenta bezbednošću informacija.</p>
                <p>Agencija Stankovićsoft je implementirala i ovaj standard u cilju očuvanja sigurnosti sistema i očuvanja poverenja naših korisnika u ovom domenu, kao i u domenu adekvatnog upravljanja potencijalnim rizicima.</p>
            </div>
            {/* <div className="row ptb--30">
                <div className="col-lg-1"></div>
                <div className="col-lg-5 col-md-12">
                    <AllPagePdfViewer  pdf={Pdf27001Srb} />
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 col-md-12">
                    <AllPagePdfViewer pdf={Pdf27001Eng} />
                </div>
            </div> */}
            {/*End 27001*/}
            <BrandTwo/>
            <Footer/>
        </>
        );

        
    }
}
export default StandardiPdf;