import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import Breadcrumb from "../common/Breadcrumb";
import Gallery from "../../blocks/GalleryCronusGo";
import ImageGallery from "react-image-gallery";





const images = [
	{
        thumbnail: '/assets/images/cronusGo/cronusgo-login.png',
        original: '/assets/images/cronusGo/cronusgo-login.png'
    },
	{
        thumbnail: '/assets/images/cronusGo/cronusgo-todo.png',
        original: '/assets/images/cronusGo/cronusgo-todo.png'
    },
	{
        thumbnail: '/assets/images/cronusGo/cronusgo-map.png',
        original: '/assets/images/cronusGo/cronusgo-map.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-raport.png',
        original: '/assets/images/cronusGo/cronusgo-raport.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-raport2.png',
        original: '/assets/images/cronusGo/cronusgo-raport2.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-map-done.png',
        original: '/assets/images/cronusGo/cronusgo-map-done.png'
    },
    {
        thumbnail: '/assets/images/cronusGo/cronusgo-done.png',
        original: '/assets/images/cronusGo/cronusgo-done.png'
    }
	
]

class CronusGo extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          width:window.innerWidth,
          height:window.innerHeight
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
     componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    render(){
        var Galerija=<Gallery/>;
        if (this.state.width<=1000){
            Galerija=<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />;
        }
        return(
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>CronusGo"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
                />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                <Breadcrumb title="CronusGo"/>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
										<div className="section-title">
											<h3 className="title">Cronus Go</h3>
										</div>
                                        <p className="subtitle"></p>
                                       
                                        <p>Aplikacija koja služi za pomoć terenskim radnicima zaposlenih u kancelarijama javnih izvršitelja. </p>
                                        <p>Svaki radnik na terenu može preko mobilnog uređaja da dobije svoje dnevne zadatke uz detalje kao što su šifra predmeta za koji je vezan zadatak, lokacija na mapi i objektivi zadatka.</p>
                                        <p>Cronus Go radi sa bazom podataka <a href="/cronus">Cronus</a>-a i zadaci koje dobije su tamo definisani i prosleđeni za izrvšenje. </p>
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Praćenje zadataka</h4>
										</div>
                                        
										
										<p>Svaki radnik prilikom provere dobije novu listu zadataka za izvršavanje iz baze i ima pregled koji su od njih izvršeni.</p>
                                        <p>Prilikom izvršavanja zadatka moguće je napraviti fotografije objekata na terenu, kao i bilo čega što je neophodno zabeležiti. Ukoliko telefon ima konekciju sa internetom te fotografije se automatski otpremaju i mogu se pregledati kroz Cronus i time utvrditi da li je terenski zadatak obavljen uspešno ili ne. </p>
                                        <p>Aplikacija takođe omogućava detaljno izveštavanje o tome da li je zadatak izvršen uspešno ili ne, kao i detalje kako je izvršen.</p>

										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Opcije koje Cronus Go nudi:</h4>
										</div>
                                        <ul style={{fontSize:18}}>
                                            <li>Zaštita privatnosti aplikacije serijskim brojem</li>
											<li>Lista dodeljenih zadataka sa detaljima</li>
											<li>Adrese predstavljene lokacijama na mapi </li>
											<li>Detaljna evidencija stanja zadatka i bliži opis izvršenja</li>
											<li>Dodatni prilozi u vidu tekstualnog komentara ili fotografija </li>
											<li>Daljinska kontrola zadataka i njihovoh izvršenja u <a href="/cronus">Cronusu</a></li>
										</ul>

                                        <div className="gallery">
											{Galerija}
										</div>
                                        <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                        <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href="../portfolio#cronusgo" >Nazad na aplikacije</a></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default CronusGo;
