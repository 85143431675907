import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import Breadcrumb from "../common/Breadcrumb";
import Gallery from "../../blocks/GalleryUpis";
import ImageGallery from "react-image-gallery"

const images = [
   
	{
        thumbnail:  '/assets/images/upis/upis-login.png',
        original: '/assets/images/upis/upis-login.png'
    }, 
    {
        thumbnail:  '/assets/images/upis/login2.png',
        original: '/assets/images/upis/login2.png'
    },
	{
        thumbnail: '/assets/images/upis/upis-pocetna.png',
        original: '/assets/images/upis/upis-pocetna.png'
    },
	{
        thumbnail: '/assets/images/upis/upis-prijava1.png',
        original: '/assets/images/upis/upis-prijava1.png'
    },
    {
        thumbnail: '/assets/images/upis/upis-prijava2.png',
        original: '/assets/images/upis/upis-prijava2.png'
    }
    ,
    {
        thumbnail: '/assets/images/upis/upis-prijava3.png',
        original: '/assets/images/upis/upis-prijava3.png'
    }
    ,
    {
        thumbnail: '/assets/images/upis/upis-prijava-nedovoljno-dokumentacije.png',
        original: '/assets/images/upis/upis-prijava-nedovoljno-dokumentacije.png'
    }
    ,
    {
        thumbnail: '/assets/images/upis/upis-stampa.png',
        original: '/assets/images/upis/upis-stampa.png'
    }
    ,
    {
        thumbnail: '/assets/images/upis/upis-poruke.png',
        original: '/assets/images/upis/upis-poruke.png'
    }
	
	
]

class Nasport extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          width:window.innerWidth,
          height:window.innerHeight
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    render(){
        var Galerija=<Gallery/>;
        if (this.state.width<=1000){
            Galerija=<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />;
        }
        return(
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>Upis"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
               />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                {/* Start Breadcrump Area */}
               <Breadcrumb title="e-Upis"/>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
									
										<div className="section-title">
											<h3 className="title">O Upisu</h3>
										</div>
										
                                        <p><strong>Upis</strong> je aplikacija napravljena da <strong>olakša proces prijave za polaganje prijemnog ispita</strong>. Ceo proces prijave je prebačen u elektronski format i omogućeno je kandidatima da preko interneta završe sve detalje oko prijave, bez potrebe da se fizički ide na fakultet.</p>
										
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Proces prijave</h4>
										</div>
										
                                        <p>Počinje se registracijom preko e-maila, uz dodatne sigurnosne potvrde da bi onemogućili prijave u tuđe ime. </p>
                                        
										<p>Nakon registracije počinje unos podataka vezanih za kandidata i podataka za koji studijski program želi da se prijavi.</p>
                                        
										<p>Omogućena je opcija da kandidat izabere ako želi da se njegov prijemni rangira i na drugim studijskim programima, ukoliko to fakultet dozvoljava. To znači da ukoliko nema dovoljno bodova da upiše studijski program koji je primarno izabrao, kandidat se rangira i na drugim studijskim programima zbog jedinstvenosti bodovanja sa prijemnog ispita.</p>
                                        
										<p>Ukoliko se kandidat prijavljuje u okviru <strong>upisa kandidata u okviru afirmativnih mera</strong> (pripadnik romske nacionalne manjine, osoba sa invaliditetom ili je završio školu u inostranstvu) postoji opcija koju kandidat može da izabere i biće rangiran u okviru upisa kandidata u okviru afirmativnih mera.</p>
										
                                        <p>Kandidati pored ličnih podataka unose i podatke o završenoj srednjoj školi, prosečnim ocenama i stranim jezicima. Moguće je i naglasiti ukoliko je kandidat već upisivao fakultet na budžetu ranije.</p>
										
                                        <p>Aplikacija nudi opcije da se sva neophodna i dodatna dokumenta otpreme u pdf/doc/docx/png ili jpg formatu. Dakle potrebno je samo skenirati sva dokumenta (očitanu ili kopiranu ličnu kartu, svedočanstva iz srednje škole, diplomu, uplatnicu naknade za polaganje prijemnog ispita i dr). U tu svrhu, potrebno je skenirati sva dokumenta.</p>
                                        
										<p>Aplikacija na svaki pokušaj završetka prijave obavesti kandidata ukoliko nešto nije popunjeno kako treba. Ukoliko je kandidat ispravno popunio sve podatke, aplikacija ga obaveštava da je uspešno završio proces prijave i o tome dobija potvrdu na svoju email adresu.</p>
													  
										<p>Nakon uspešnog unosa svih podataka i potvrde prijave iz studentske službe, moguće je odštampati svoj prijavni list.</p>
                                        
										<p>Ukoliko se prekine unos podataka u nekom delu, Upis će zapamtiti sve uneto do tog trenutka i pri sledećem logovanju dozvoliti da kandidat nastavi unos tamo gde je stao.</p>
                          
                                        <p>Ako kandidat naiđe na bilo kakav problem ili nejasnoću, može lako kontaktirati podršku kroz aplikaciju i dobiti pomoć u najkraćem roku.</p>
										
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Administratorski deo</h4>
										</div>
										
                                        <p>Kao administrator sistema moguće je pregledati sve registrovane korisnike po statusu, odnosno, da li je prijava u toku, predata ili potvrđena od strane studentske službe.</p>
										
                                        <p>Moguće je štampati grupne spiskove prijavnih listova ili prijavljenih kandidata.</p>
										
                                        <p>Administrator ima pristup porukama kandidata na koje može da odgovori i tako pruži pomoć kandidatima.</p>
										
                                        <div className="gallery">
											{Galerija}
										</div>
                                        <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                 <div className="slide-btn"><a className="rn-button-style--2  btn-solid" href="../portfolio#upis" >Nazad na aplikacije</a></div>
                                </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Nasport;
