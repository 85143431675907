import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";


import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import Breadcrumb from "../common/Breadcrumb";
import Gallery from "../../blocks/GalleryDiploma";
import ImageGallery from "react-image-gallery";





const images = [
	{
        thumbnail: '/assets/images/diploma/diploma-login.png',
        original: '/assets/images/diploma/diploma-login.png'
    },
	{
        thumbnail: '/assets/images/diploma/diploma-pocetna.png',
        original: '/assets/images/diploma/diploma-pocetna.png'
    },
	{
        thumbnail: '/assets/images/diploma/diploma-pretraga.png',
        original: '/assets/images/diploma/diploma-pretraga.png'
    },
	{
        thumbnail: '/assets/images/diploma/diploma-drzave.png',
        original: '/assets/images/diploma/diploma-drzave.png'
    }
	
]

class Diploma extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          width:window.innerWidth,
          height:window.innerHeight
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
     componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    render(){
        var Galerija=<Gallery/>;
        if (this.state.width<=1000){
            Galerija=<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />;
        }
        return(
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>E-Diploma"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
               />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                <Breadcrumb title="E-Diploma"/>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <p className="subtitle"></p>
                                        <p>U saradnji sa Univerzitetom u Nišu, razvili smo aplikaciju koja služi za automatizaciju procesa izrade diploma. Na osnovu neophodnih podataka kao što su fakultet, studijski program, položeni ispiti, završni rad, generalije o studentu, i dr. naša aplikacija pravi nacrt diplome, kao i dodatak diplomi, koji nakon odbobrenja mogu da se odštampaju, bez ikakve potrebe da se nešto ručno dodaje. </p>

                                        <p>Zbog integracije sa <a href="/fakultis">Fakultis</a>-om podatke za diplomu moguće je učitati iz aplikacije, a kao dodatnu opciju omogućili smo i uvoz podatak iz XML-a. Naravno, mnogo je pouzdanije koristiti podatke iz Fakultisa jer se tu podaci unose i potvrđuju u toku celog toka studiranja. </p>

                                        <p classname="subtitle">
                                         
                                        </p>

                                        <div className="gallery">
											{Galerija}
										</div>
                                        <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                 <div className="slide-btn"><a className="rn-button-style--2  btn-solid" href="../portfolio#diploma" >Nazad na aplikacije</a></div>
                                </div>
                                </div>
                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Diploma;
