import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiCheck } from "react-icons/fi";
import ListCloud from "../component/common/ListCloud"
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Breadcrumb from "./common/Breadcrumb";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,"
                />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title="Naša snaga"></Breadcrumb>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start razvoj*/}
                                        <div className="row sercice-details-content pb--80 align-items-center" id="razvoj">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/development.jpg" 
														style={{ height:"28%" }}
														alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                     <h4 className="title">Razvoj aplikacija</h4>
                                                     <p>Pružanje usluga u polju softverskog inženjerstva je naša osnovna delatnost. Počinjemo sa prikupljanjem najkvalitetnijih ideja, uzimajući u obzir zahteve klijenta, razvijamo ih i kreiramo osnovnu strukturu programa koju kasnije materijalizujemo u stabilnu aplikaciju koja ima sve predviđene funkcionalnosti i može se lako nadograditii ako ima potrebe. </p>
                                                     <p>U drugoj fazi, naš tim programera piše programske kodove iz prethodno dizajniranog rešenja. Softver se kasnije testira dok u međuvremenu programeri ispravljaju moguće greške koje su nastale u fazi testiranja. Na kraju, kada rešenje zadovolji kriterijume korisnika, program je spreman za isporuku. </p>
                                                     <p>Naš tim će vam omogućiti da:</p>
                                                    <ul className="list-style--1">
                                                        <li><FiCheck />Otkrijete nove poslovne prilike kroz tehnološku inovativnost</li>
                                                        <li><FiCheck />Poboljšate efikasnost i operativnost</li>
                                                        <li><FiCheck />Smanjite troškove rada</li>
                                                        <li><FiCheck />Ne brinete o sigurnosti i privatnosti podataka</li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End razvoj */}

                                        {/* Start održavanje */}
                                        <div className="row sercice-details-content align-items-center" id="odrzavanje">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Održavanje i ažuriranje</h4>
                                                    <p> WEB aplikacije su suština današnjeg načina poslovanja, ali sa ubrzanim tempom razvoja informacionih tehnologija, neke poslovne aplikacije zaostaju za drugima.
                                                        Stanković Soft ima cilj da klijentima pomogne u dizajniranju, kreiranju, testiranju i upravljanju njihove aplikacije kako bi održala svoju dugoročnu konkurentsku prednost.
                                                         Kombinacija fleksiblinosti, tehnološke ekspertize i poznavanja materije u kojoj naša kompanija izrađuje softver omogućava korisnicima da povećaju svoju produktivnost,
                                                          poboljšaju kvalitet rada i smanje troškove.
                                                    </p>
                                                    <p>Upravo iz ovih razloga je neophodno da se WEB aplikacije održavaju konzistentno. Na taj način su naši klijenti sigurni da aplikacije koje su dobili neće zastareti i raditi sporije ili sa bilo kakvim hendikepom.
                                                        Takođe, uz ažuriranja novih tehnologija može se čak i poboljšati rad aplikacije, bilo u vidu brzine rada ili u vidu dodatnih vizuelnih ili fukncionalnih opcija.
                                                    </p>
                                                    <p>
                                                    Svaka promena na aplikaciji, manja ili veća, prolazi kroz test fazu i ukoliko nema grešaka, nova verzija se isporučuje korisnicima. Aplikaciju ažuriramo ukoliko postoji zahtev klijenta za nekom promenom zbog optimizacije koda ili nadogradnje same aplikacije.
                                                    </p>
                                                   
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/odrzavanje.jpg" alt="Service Images"/>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        {/* End  održavanje */}
                                        <br/><br/>
                                        {/* Start Test */}
                                        <div className="row sercice-details-content pb--80 align-items-center" id="test">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/test.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Test verzije programa</h4>
                                                    <ul className="liststyle">
                                                        <li>Kako bi se korisnik upoznao sa programom i upoznao neke njegove prednosti postoji mogućnost otvaranja test verzije. Korisnik dobija privremeno korisničko ime i lozinku i loguje se na aplikaciju. </li>
                                                        <li>Svi podaci koje korisnik unese upisuju se u test bazu, tako da na test verziji aplikacije možete slobodno da eksperimentišete bez bojazni da ćete nešto pogrešno uneti u program. </li>
                                                        <li>Ukoliko želite da probate test verziju aplikacije, potrebno je da se prijavite na našu e-mail adresu office@stankovicsoft.com. Mi ćemo potom proveriti trenutnu dosupnost naših test verzija i obavestiti vas kada je moguće logovati se na test aplikaciju. </li>
                                                       
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Test*/}

                                         {/* Start Fix */}
                                         <div className="row sercice-details-content align-items-center" id="fix">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                   <h4 className="title">Brzo otklanjanje grešaka</h4>
                                                   <p>Ukoliko se javi neka greška pri korišćenju aplikacije sve što korisnik treba da uradi je da pošalje mail sa detaljnim opisom greške i naši programeri će je u najkraćem roku popraviti uz objašnjenje zašto je do toga došlo i kako da se to ne ponavlja.
                                                        Detaljan opis greške podrazumeva skup koraka koji se desio pred samu grešku, da bi programeri mogli da reprodukuju i uoče tačno ono što je prijavljeno. </p>
                                                    <p>Isto važi i ukoliko se aplikacija ne ponaša kao što je korisnik predvideo, u smislu načina unosa, navigacije kroz aplikaciju, itd. uz jednostavan dogovor korigovaćemo tako da je korisniku brži, lakši, i praktičniji rad u aplikaciji.</p>
                                                    
                                                   
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/fix.jpg" alt="Service Images"/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Fix*/}

                                        
                                        {/* Start cloud */}
                                        <div className="row sercice-details-content align-items-center" id="support" style={{marginTop:100}}>
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/support.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Korisnička podrška</h4>
                                                    
                                                    <p>Brinemo se za naše korisnike i trudimo se da uz našu pomoć i obuku za rad u našim aplikacijama unapredimo njihovo poslovanje. </p>
                                                    <p>Za sve naše aplikacije smo tu da pokažemo korisnicima na	koji način ih mogu najpraktičnije koristiti uz upotrebu svih mogućih opcija koje nude.</p>
                                                    <p>Pomažemo korisnicima da otkriju gde je nastala greška i kako da je poprave, ukoliko je došlo do greške pri korišćenju aplikacije. </p>
                                                    <p>Tu smo da prilagodimo izgled i opcije po volji naših korisnika, ukoliko na neki način možemo prilagoditi nešto da bude praktičnije bez da oštetimo druge segmente apliakcije, tu smo da izađemo u susret.</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End cloud*/}

                                         {/* Start digital */}
                                         <div className="row sercice-details-content align-items-center" id="digital" style={{marginTop:100}}>
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Automatizacija</h4>
                                                   <p>Kako se savremena tehnologija konstantno menja u svim aspektima, neophodno je da svaka organizacija ima program za transformaciju poslovanja u elektronski oblik kojom će se zadovoljiti stalno rastuća potreba za inovacijama i poboljšanjima. </p>
                                                    <p>Naše aplikacije su napravljene na način da automatizuju sve procese poslovanja kod kojih je to moguće. Na taj način većina rutinskih poslova se ostavlja za izvršenje samoj aplikaciji dok naši klijenti mogu da se skoncentrišu na upravljanje podacima i delove posla koji se ne mogu automatizovati.</p>
                                                    <p>S obzirom da se neki naši programi stalno menjaju (zbog promena propisa, tarifa, načina poslovanja), trudimo se da stalno ažuriramo naše aplikacije. Stremimo ka tome da svi poslovi mogu da se završe korišćenjem naših aplikacija. </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/automatisation.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End digital*/}

                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;