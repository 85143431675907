import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ListCronus from '../../component/common/ListCronus'
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import Breadcrumb from "../common/Breadcrumb";

class Vaga extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          width:window.innerWidth,
          height:window.innerHeight
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    render(){
        
        return(
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>Vaga"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
               />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                {/* Start Breadcrump Area */}
               <Breadcrumb title="Vaga"/>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <p>
                                            Web aplikacija VAGA nudi rešenja koja automatizuju proces merenja kamiona na kolskoj vagi za potrebe Michelin fabrike u Pirotu, izrađena u C# korišćenjem tehnologija koje pruža ASP.NET CORE 8, dok je šema baze podataka projektovana u MSSQL-u.
                                        </p>
                                        <p>
                                            Aplikacija automatski beleži podatke o izlaznom i ulaznom merenju perifernih uređaja (kolske vage i kamera), koji se upoređuju sa unetim podacima sa carinske deklaracije radi slanja odgovarajućeg svetlosnog signala na semafor za dozvolu/zabranu daljeg kretanja kamiona i štampa izveštaj sa potrebnim podacima. Aplikaciji je moguće pristupiti putem LDAP parametara za logovanje.
                                        </p>
                                        
                                        <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                        <div className="slide-btn"><a className="rn-button-style--2  btn-solid" href="../portfolio#cronus">Nazad na aplikacije</a></div>
                                    </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Vaga;
