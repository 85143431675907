import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../common/Breadcrumb";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import ListEindeks from "../../component/common/ListEindex"
import GalleryIndeks from "../../blocks/GalleryMindeks"
import ImageGallery from "react-image-gallery";




const images = [
	{
        original: '/assets/images/fakulteti/mindeks/mindeks-login.png',
        thumbnail: '/assets/images/fakulteti/mindeks/mindeks-login.png'
    },
	{
        original: '/assets/images/fakulteti/mindeks/mindeks-meni.png',
        thumbnail: '/assets/images/fakulteti/mindeks/mindeks-meni.png'
    },
	{
        original: '/assets/images/fakulteti/mindeks/mindeks-eprijava.png',
        thumbnail: '/assets/images/fakulteti/mindeks/mindeks-eprijava.png'
    },
	{
        original: '/assets/images/fakulteti/mindeks/mindeks-eprijava2.png',
        thumbnail: '/assets/images/fakulteti/mindeks/mindeks-eprijava2.png'
    },
	{
        original: '/assets/images/fakulteti/mindeks/mindeks-espbPoGodinama.png',
        thumbnail: '/assets/images/fakulteti/mindeks/mindeks-espbPoGodinama.png'
    },
    {
        original: '/assets/images/fakulteti/mindeks/mindeks-ispiti.png',
        thumbnail: '/assets/images/fakulteti/mindeks/mindeks-ispiti.png'
    },
	{
        original: '/assets/images/fakulteti/mindeks/mindeks-semestri.png',
        thumbnail: '/assets/images/fakulteti/mindeks/mindeks-semestri.png'
    },
	{
        original: '/assets/images/fakulteti/mindeks/mindeks-uplatnica.png',
        thumbnail: '/assets/images/fakulteti/mindeks/mindeks-uplatnica.png'
    }
	
]
class Mindeks extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          width:window.innerWidth,
          height:window.innerHeight
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    render(){
        var Galerija=<GalleryIndeks/>;
        if (this.state.width<=1000){
            Galerija=<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />;
        }
        return(
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>m-Indeks"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
               />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                {/* Start Breadcrump Area */}
               <Breadcrumb title="m-Indeks"/>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
										<div className="section-title">
											<h3 className="title">Studentski portal</h3>
										</div>
                                        <p className="subtitle"></p>
                                        <p>Ova aplikacija je napravljena da prati funkcionalnosti portala e-Indeks i podrži zahteve kako studenata tako i administratora preko mobilnih uređaja.</p>

                                        <p>Logovanjem u aplikaciju student dobija sve informacije o njegovom školovanju, i to: položene ispite, prijavljene ispite po rokovima, izveštaje o uplaćenoj školarini, broju osvojenih espb bodova za svaku školsku godinu njegovog studiranja, spisak upisanih predmeta za svaku školsku godinu, raspored časova, raspored ispita.</p>
										
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Prijava ispita</h4>
										</div>
                                        <p>Elektronska prijava ispita funkcioniše potpuno isto kao kod dekstop verzije, odnosno e-indeks portala. Ovo se odnosi na uplate, cenovnike i način izbora ispita za prijavu.</p>
										
										
                                        

										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Opcije m-Indeksa</h4>
										</div>
                                        <ul style={{fontSize:18}}>
											<li>Elektronska prijava ispita</li>
											<li>Raspored časova predmata koje student sluša</li>
											<li>Raspored preostalih ispita koje student sluša</li>
											<li>Uvid u sve prošle prijave ispita</li>
											<li>Uvid u finansijsko stanje na računu (da li i koliko student ima uplaćeno sredstava na račun fakulteta)</li>
											<li>Istoriju uplata</li>
											<li>Podaci za uplatu</li>
											<li>Podaci o kolokvijumima i terminima za konsultacije</li>
											<li>Vesti o fakultetu i ispitnim rokovima</li>
										</ul>

                                        <div className="gallery">
											{Galerija}
										</div>
                                        <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                        <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href="../portfolio#mindeks" >Nazad na aplikacije</a></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Mindeks;
