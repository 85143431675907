import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let trenutnaGodina = parseInt(new Date().getFullYear());
        let pocetnaGodina = 2013;
        let Data = [
            {
                countStart:0,
                countNum : trenutnaGodina - pocetnaGodina,
                countTitle: 'Divnih godina poslovanja, u toku kojih smo unapredili svoje aplikacije i broj zadovoljnih klijenata. Danas se razvijamo u istom smeru.',
            },
            {   
                countStart:0,
                countNum : 29,
                countTitle: 'Fakulteta i visokoškolskih ustanova koristi naše aplikacije za vođenje studentske evidencije preko kojih se uz intuitivan interfejs omogućavaju mnoge online opcije koje prate proces od upisa do diplomiranja studenata.',
            },
            {
                countStart:150,
                countNum : 221,
                countTitle: 'Javnih izvršitelja nam je poklonilo poverenje od 234 imenovanih aktivnih javnoizvršiteljskih kancelarija, i zauzvrat steklo mogućnost da sa lakoćom unose i obrađuju svoje predmete. Aplikacija je u potpunosti prilagođena praćenju rada i potrebama javnoizvršiteljskih kancelarija.',
            }

        ];

        return(
            <Fragment>
                <div className="row">
                    {Data.map( (value, index) => (
                        <div className="counterup_style--1 col-sm-4 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp start= {value.countStart} end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countTitle}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;
