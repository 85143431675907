import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../common/Breadcrumb";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderThree";
import Footer from "../../component/footer/Footer";
import GalleryNasport from "../../blocks/GalleryNasport";
import ImageGallery from "react-image-gallery";


const images = [
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-login.png',
        original: '/assets/images/fakulteti/nasport/nasport-login.png'
    },
    {
        thumbnail: '/assets/images/fakulteti/nasport/nasport-kalendar.png',
        original: '/assets/images/fakulteti/nasport/nasport-kalendar.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-konsultacije-potvrda.png',
        original: '/assets/images/fakulteti/nasport/nasport-konsultacije-potvrda.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-materijali-sednica.png',
        original: '/assets/images/fakulteti/nasport/nasport-materijali-sednica.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-nerazvedeni-zapisnici.png',
        original: '/assets/images/fakulteti/nasport/nasport-nerazvedeni-zapisnici.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-predmet-detalji-nastava.png',
        original: '/assets/images/fakulteti/nasport/nasport-predmet-detalji-nastava.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-predmet-detalji-studenti.png',
        original: '/assets/images/fakulteti/nasport/nasport-predmet-detalji-studenti.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-predmeti-pretraga.png',
        original: '/assets/images/fakulteti/nasport/nasport-predmeti-pretraga.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-zapisnik.png',
        original: '/assets/images/fakulteti/nasport/nasport-zapisnik.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-prolaznost.png',
        original: '/assets/images/fakulteti/nasport/nasport-prolaznost.png'
    },
	{
        thumbnail: '/assets/images/fakulteti/nasport/nasport-izvestaj-o-radu.png',
        original: '/assets/images/fakulteti/nasport/nasport-izvestaj-o-radu.png'
    }
	
]

class Nasport extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          width:window.innerWidth,
          height:window.innerHeight
        }
        this.openModal = this.openModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        this.updateWindowDimensions ();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions = () =>  {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    render(){
        var Galerija=<GalleryNasport/>;
        if (this.state.width<=1000){
            Galerija=<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />;
        }
        return(
            <React.Fragment>
                <PageHelmet pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge>Nastavnicki portal"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,CronusGo,cronusgo,CronusBank,cronusbank,diploma,raspodela,ustanova,ustanove,visokoškolske,visokoskolske,praćenje,pracenje"
                />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
             
                {/* Start Breadcrump Area */}
                <Breadcrumb title="e-Nastavnik"/>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
										<div className="section-title">
											<h3 className="title">Nastavnički portal</h3>
										</div>
                                        <p className="subtitle"></p>
                                        <p><strong>Nastavnički portal je</strong> veb aplikacija <strong>namenjena praćenju</strong> realizacije <strong>obaveza nastavnika</strong> visokoškolske ustanove. Portal može služiti i za <i>štampu i elektronsko popunjavanje zapisnika sa ispita od strane nastavnika, definisanja organizacije nastave, načina ispitivanja</i> i sl. Preko portala nastavnici mogu komunicirati sa studentima i kačiti materijale koje će studenti preuzimati sa studentskog portala ili sajta fakulteta.</p>

										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Evidencija nastave</h4>
										</div>
                                        <p>Prva opcija na kalendaru jeste <strong>evidencija nastave</strong>. Biranjem termina nastave otvara se prozor u kome imate mogućnost unosa podataka o održanoj nastavnoj jedinici u predviđenim terminima. To može biti potvrda o održanim časovima ili naznaka da časovi nisu održani. Redovno unošenje ovih obaveza je dužnost svakog ko je angažovan za realizaciju nastave u tekućoj školskoj godini. Nastavnik je dužan da unese i naziv nastavne jedinice koja je obrađena u terminu predavanja. Ukoliko čas nije održan obaveza je nastavnika da unese razlog neodržavanja nastave. Tom prilikom je moguće ujedno i zakazati nadoknadu neodržanog časa. Sled poteza u okviru nastavnih aktivnosti biće verifikovan u izveštaju koji je dostupan nastavnicima angažovanim na predmetu, kao i nadležnim službama i Dekanatu.</p>
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Kalendar obaveza</h4>
										</div>
										<p>Na portalu su uneti i termini svih pismenih i usmenih delova ispita na predmetima na kojim se izvodi nastava u toku tekuće školske godine. Oni ulaze u izveštaj o radu jer se podrazumeva da moraju biti održani. Konsultacije će takođe biti verifikovane na portalu. NN Veća može zakazivati Dekan fakulteta i termin će se pojaviti na kalendaru svakog nastavnika. Potrebno je da nastavnik potvrdi prisustvo na zakazani termin i izjasni se o prisustvu. Na kalendaru se može zakazati i druga vrsta obaveza koja predstavlja lično angažovanje nastavnika, ali ona neće ući u zvanični izveštaj već služi samo kao podsetnik.</p>
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Predmeti na kojima je nastavnik angažovan</h4>
										</div>
										<p>Postoji mogućnost pregleda predmeta na kojima je nastavnik angažovan za tekuću školsku godinu. Kod svakog predmeta je moguće dobiti spisak studenata koji slušaju predmet te školske godine. Moguće je zakazati obavezu studentima ili ih obavestiti o promeni rasporeda, otkazivanju nastave itd. Bilo kakvo obaveštenje te vrste se automatski šalje svim studentima koji slušaju predmet na njihove email adrese. Postoji mogućnost zabrane prijave ispita studentu na osnovu toga da li je ispunio sve predispitne obaveze, odradio seminarske radove, laboratorijske vežbe i slično. Zabranu unosi nastavnik, direknto u nastavnički portal.</p>
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Nastavnički profil</h4>
										</div>
										<p>Moguće je da kroz portal nastavnik unese i održava svoju biografiju, spisak svojih publikacija, silabuse predmeta na kojima je angažovan i sve to prikazati direktno na sajt fakulteta. Isto tako nastavnik može videti rezultate ankete za ispite koje drži. Korisnici sa privilegijama „dekanata“ mogu videti izveštaj o anketiranju za bilo kog nastavnika.</p>

										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Izveštaj o radu</h4>

										</div>
										<p>Svako od nastavnika je u mogućnosti da vidi svoj izveštaj o radu, a korisnici sa privilegijom
										„dekanata“ mogu pregledati izveštaje za bilo kog nastavnika. Isto tako, svako od nastavnika može da vidi prolaznost na ispitima sa svog departmana za odabranu školsku godinu.</p>
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Zapisnik</h4>
										</div>
										<p>Preko portala je moguće da nastavnik preuzme zapisnik za ispit ili ga elektronski popuni i pošalje službi za nastavu.</p>
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Literatura za predmet</h4>
										</div>
										<p>Preko portala je moguće postaviti materijale koji će se automatski videti na sajtu fakulteta ako to administratori sajta fakulteta dozvole i prikažu, ali pored toga će to zasigurno videti studenti na svom studentskom portalu, pa je ovo pogodno za realizaciju neke vrste online nastave.</p>
										
										<div className="section-title" style={{marginTop:30}}>
											<h4 className="title">Slanje obaveštenja</h4>
										</div>
										<p>Isto tako, korisnici sa privilegijama „dekanata“ su u mogućnosti da šalju grupna obaveštenja studentima i nastavnicima. Ono što je ovde zanimljivo jeste da je moguće odabrati sve studente koji slušaju ili koji su prijavili neki od ispita u nekom roku i samo njima poslati obaveštenje. Isto tako, mogu da kroz ove opcije kače obaveze nastavnicima u smislu zakazivanja određenih sednica, veća, odbrana završnih radova i sl.</p> 
										
										<div className="gallery">
											{Galerija}
										</div>
                                        <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                        <div className="slide-btn"><a className="rn-button-style--2  btn-solid" href="../portfolio#nasport" >Nazad na aplikacije</a></div>
                                    </div>
                                    </div>
									
									
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
		
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Nasport;
