import React, { Component } from "react";




class BrandTwo extends Component{
  

    render(){
        return(
            <React.Fragment>
               
                <ul className="brand-style-2">
                    <li>
                        <a target="_blank"href="http://www.fsfv.ni.ac.rs/"><img src="/assets/images/brand/logoDif.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a target="_blank"href="http://www.ekfak.kg.ac.rs/sr/"><img src="/assets/images/brand/logoEknfakkg.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a target="_blank"href="http://www.eknfak.ni.ac.rs/src/"><img src="/assets/images/brand/logoEknfakni.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a target="_blank"href="https://www.elfak.ni.ac.rs/"><img src="/assets/images/brand/logoElfakni.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a target="_blank"href="https://www.filfak.ni.ac.rs/"><img src="/assets/images/brand/logoFilfakni.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="http://filum.kg.ac.rs"> <img src="/assets/images/brand/logoFilumkg.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="http://www.mfkg.rs/"> <img src="/assets/images/brand/logoFinkg.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="http://gaf.ni.ac.rs/"> <img src="/assets/images/brand/logoGafni.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://www.hit-vb.kg.ac.rs/"> <img src="/assets/images/brand/logoHitvb.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a target="_blank"href="https://www.masfak.ni.ac.rs/"><img src="/assets/images/brand/logoMasfakni.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://medf.kg.ac.rs/"> <img src="/assets/images/brand/logoMedfakkg.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                      <a target="_blank"href="https://www.medfak.ni.ac.rs/">  <img src="/assets/images/brand/logoMedfakni.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://www.pmf.ni.ac.rs/"> <img src="/assets/images/brand/logoPmfni.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="http://www.tf.ni.ac.rs/"> <img src="/assets/images/brand/logoTfle.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://poljfak.ni.ac.rs/"> <img src="/assets/images/brand/logoPoljfakks.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="http://www.artf.ni.ac.rs/"> <img src="/assets/images/brand/logoArtfni.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://dif.pr.ac.rs/"> <img src="/assets/images/brand/logoFsfvss.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="http://www.pfvr.ni.ac.rs/"> <img src="/assets/images/brand/logoUciteljski.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://med.pr.ac.rs/"> <img src="/assets/images/brand/logoMedfakpr.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="http://www.jura.kg.ac.rs/"> <img src="/assets/images/brand/logoPravfakkg.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://pefja.kg.ac.rs/"> <img src="/assets/images/brand/logoUcfakkg.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="http://www.ftn.kg.ac.rs/"> <img src="/assets/images/brand/logoTfckg.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://www.pmf.kg.ac.rs/"> <img src="/assets/images/brand/logoPmfkg.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://vpsle.edu.rs/"> <img src="/assets/images/brand/logoVps.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://toplicka.edu.rs/"> <img src="/assets/images/brand/logoTass.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                       <a target="_blank"href="https://www.vakademija.edu.rs/"> <img src="/assets/images/brand/logoVsva.png" alt="Logo Images"/></a>
                    </li>
                     <li>
                       <a target="_blank"href="https://www.vos.edu.rs/"> <img src="/assets/images/brand/logoEduka.png" alt="Logo Images"/></a>
                     </li>
                     <li>
                        <a target="_blank" href="https://www.addiko.rs/"><img src="/assets/images/brand/logoAdiko.png" alt="Logo Images"/></a>
                     </li>
                     <li>
                        <a target="_blank" href="https://www.michelin.rs/"><img src="/assets/images/brand/logoMichelin.png" alt="Logo Images"/></a>
                     </li>

                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;